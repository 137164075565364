import { Component, Input, OnInit } from '@angular/core';
import { AdvformDetailData } from 'src/app/_api/entities/advform-data';
import { AdvFormDetailService } from '../services/advform-detail-service.component';

@Component({
  selector: 'advform-item5-section5',
  templateUrl: './advform-item5section5.component.html',
  styleUrls: ['../../advform/advform.component.scss']
})

export class AdvformItem5Section5Component implements OnInit {

  @Input('detail') public detail: AdvformDetailData | undefined;

  public sec5G3s = new Array<any>();
  public sec5G3s_New = new Array<any>();

  public sec5Iwraps = new Array<any>();
  public sdK3s = new Array<any>();

  constructor(
    public advFormDetailService: AdvFormDetailService
  ) { }

  public ngOnInit(): void {
    this.sec5G3s = this.advFormDetailService.getDeepObjectArray(this.detail, 'ScheduleD.SD_5_G_3');
    this.sec5G3s_New = this.advFormDetailService.getDeepObjectArray(this.detail, 'ScheduleD.SD_5_G_3_New');
    this.sec5Iwraps = this.advFormDetailService.getDeepObjectArray(this.detail, 'ScheduleD.SD_5_I_2');
    this.sdK3s = this.advFormDetailService.getDeepObjectArray(this.detail, 'ScheduleD.SD_5_K_3');
  }

}
