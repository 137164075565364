import { NgModule } from '@angular/core';
import { BuyLicenseComponent } from './buy-license.component';
import { LandingPageComponent } from './landing-page.component';
import { ConcurrencyModule } from '@concurrency/angular';
import { environment } from '../../environments/environment';

@NgModule({
    declarations: [
        BuyLicenseComponent,
        LandingPageComponent
    ],
    entryComponents: [
        BuyLicenseComponent,
        LandingPageComponent
    ],
    imports: [
        ConcurrencyModule.forRoot(environment)
    ]

})
export class StaticContentModule {}
