export function parse(value?: string|null): number|null {
    if (value == null) {
        return null;
    }

    const result = parseFloat(value);
    if (isNaN(result)) {
        return null;
    }

    return result;
}

export function sum(array: number[], precision: number = 2): number {
    return array.reduce((a, b) => round(a + b, precision), 0);
}

export function average(array: number[], precision: number = 2): number|null {
    if (array == null || array.length === 0) {
        return null;
    }

    return sum(array, precision) / array.length;
}

export function median(array: number[], precision: number = 2): number|null {
    if (array == null || array.length === 0) {
        return null;
    }

    array.sort((a, b) => a - b);

    const half = Math.floor(array.length / 2);

    if (array.length % 2) {
        return array[half];
    } else {
        const rounded = round(array[half - 1] + array[half], precision);
        return rounded / 2.0;
    }
}

export function roundToFixed(num: number, precision: number = 2): string {
    return round(num, precision).toFixed(precision);
}

export function round(num: number, precision: number = 2): number {
    const factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
}

export function clone<T>(obj: T): T {
    try {
        if (obj) {
            return JSON.parse(JSON.stringify(obj));
        } else {
            throw new Error(`Parameter 'obj' was null or undefined.'`);
        }
    } catch (error) {
        console.log('Failed to parse:');
        console.log(obj);
        throw error;
    }
}

export function delimitDigitsByComma(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function selectMany<TObject, TProperty>(
    array: Array<TObject>,
    propertyFunction: (obj: TObject) => TProperty[]
): Array<TProperty> {
    return array.map(propertyFunction)
                .reduce((prev, curr) => prev.concat(curr), []);
}

export function groupBy<T>(array: T[], func: Function): T[][] {
    const groups: { [index: string]: T[] } = {};
    array.forEach((obj: T) => {
        const group = JSON.stringify(func(obj));
        groups[group] = groups[group] || [];
        groups[group].push(obj);
    });
    return Object.keys(groups).map((group) => groups[group]);
}

export function isNullOrEmpty(str?: string|null): boolean {
    return str === '' || str == null;
}

export function notNull<T>(value: T | null | undefined): value is T {
    return value != null;
}

export function always<T>(observable: Observable<T>, action: Function): Observable<T> {
    observable.subscribe(() => action(), () => action(), () => action());

    return observable;
}

export function sortBy<TObject>(array: TObject[], selector: (obj: TObject) => any): TObject[] {
    return array.sort((leftObject, rightObject) => {
        const a = selector(leftObject);
        const b = selector(rightObject);

        if (a instanceof Date) {
            const dateA = new Date(a);
            const dateB = new Date(b);

            return dateA.getTime() - dateB.getTime();
        } else if (typeof(a) === 'number' && typeof(b) === 'number') {
            return a - b;
        } else if (typeof(a) === 'string' && typeof(b) === 'string') {
            return '' + a.localeCompare(b);
        }

        return a;
    });
}

export function toPreciseString(data: string|number|null, precision: number): string|undefined {
    if (typeof data === 'number') {
        return roundToFixed(data, precision);
    } else if (typeof data === 'string') {
        const result = parse(data);
        if (result != null) {
            return roundToFixed(result, precision);
        }
    }

    return undefined;
}

export function pad(str: string|number = '', width: number = 2, padding: string = '0'): string {
    str = str + '';
    return str.length >= width
        ? str
        : new Array(width - str.length + 1).join(padding) + str;
}

export function isISODateEqual(left: string, right: string): boolean {
    const leftDate = left.split('T')[0];
    const rightDate = right.split('T')[0];

    return leftDate === rightDate;
}

// TODO: Where to put this
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

export function ngbDateStructToDate(date?: NgbDateStruct|null): Date {
    if (date == null) {
        return new Date();
    }

    const day = ('0' + date.day.toString()).slice(-2);
    const month = ('0' + date.month.toString()).slice(-2);

    return new Date(`${date.year}-${month}-${day}T00:00:00`);
}

export function ngbDateStructToString(date: NgbDateStruct|null): string {
    if (date == null) {
        return new Date().toISOString().split('T')[0].concat('T12:00:00');
    }

    const day = ('0' + date.day.toString()).slice(-2);
    const month = ('0' + date.month.toString()).slice(-2);

    return `${date.year}-${month}-${day}T12:00:00`;
}
