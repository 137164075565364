import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({ template: '' })
export class LoginRedirectComponent implements OnInit {
    constructor(
        private router: Router) { }

    public ngOnInit(): void {
        this.router.navigate(['/home/dashboard']);
    }
}