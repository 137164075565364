import { Component, Input, OnInit } from '@angular/core';
import {AdvformDetailData} from '../../_api/entities/advform-data';
import { ADVStates } from '../../_api/entities/states';

@Component({
  selector: 'advform-item2c',
  templateUrl: './advform-item2c.component.html',
  styleUrls: ['../../advform/advform.component.scss']
  })
export class AdvformItem2cComponent implements OnInit {

  @Input('detail') public detail: AdvformDetailData | undefined;

  public states: ADVStates;

  constructor() {
    this.states = {
      AL: 'N',
      AK: 'N',
      AZ: 'N',
      AR: 'N',
      CA: 'N',
      CO: 'N',
      CT: 'N',
      DE: 'N',
      DC: 'N',
      FL: 'N',
      GA: 'N',
      GU: 'N',
      HI: 'N',
      ID: 'N',
      IL: 'N',
      IN: 'N',
      IA: 'N',
      KS: 'N',
      KY: 'N',
      LA: 'N',
      ME: 'N',
      MD: 'N',
      MA: 'N',
      MI: 'N',
      MN: 'N',
      MS: 'N',
      MO: 'N',
      MT: 'N',
      NE: 'N',
      NV: 'N',
      NH: 'N',
      NJ: 'N',
      NM: 'N',
      NY: 'N',
      NC: 'N',
      ND: 'N',
      OH: 'N',
      OK: 'N',
      OR: 'N',
      PA: 'N',
      PR: 'N',
      RI: 'N',
      SC: 'N',
      SD: 'N',
      TN: 'N',
      TX: 'N',
      UT: 'N',
      VT: 'N',
      VI: 'N',
      VA: 'N',
      WA: 'N',
      WV: 'N',
      WI: 'N',
      WY: 'N'
     };
  }

  private setState(name: string): void {
      const ev = `this.states.${name}='Y';`
      eval(ev);
  }

  public ngOnInit(): void {
    if (this.detail != null && this.detail.NoticeFiled) {
      for (let i = 0; i < this.detail.NoticeFiled.States.length; ++i) {
          this.setState(this.detail.NoticeFiled.States[i].RgltrCd);
      }
    }
  }

}
