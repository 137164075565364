import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalManager, SubscriberEntity } from '@concurrency/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { PageMargin } from '@progress/kendo-drawing/pdf';
import { zip } from 'rxjs';
import { FirmSummaryResult } from 'src/app/_api/entities/reports';
import { UserProfile } from 'src/app/_api/entities/user-profile';
import { LoadingSpinner } from 'src/app/_concurrency/spinner/loading-spinner';
import { DataStore } from 'src/app/_form-adv/data/data.store';
import { UserStore } from 'src/app/_form-adv/user/user.store';
import { DocumentLoadingModalComponent, ModalType } from 'src/app/advform/document-loading-modal.component';
import {ConfirmationDialogService} from '../modals/confirmation/confirmation-dialog.service';

@Component({ templateUrl: 'reports-firm-summary.component.html', styleUrls: ['../../../assets/css/reports.scss'] })
export class ReportsFirmSummaryComponent extends SubscriberEntity implements OnInit {

    public firmSummary!: FirmSummaryResult;
    public canSubscribe = false;
    public subscribed = false;
    public isPremiumUser = false;
    public selectedFilingsDate : any;

    @ViewChild('pdf') public pdf!: PDFExportComponent;
    @ViewChild('pdfDataLink') public pdfDataLink!: ElementRef;
    @ViewChild('actionsContainer') public actionsContainer!: ElementRef;

    constructor(
        private route: ActivatedRoute,
        private dataStore: DataStore,
        private router: Router,
        private userStore: UserStore,
        private modalManager: ModalManager,
        private modalService: NgbModal,
        public loadingSpinner: LoadingSpinner,
        private confirmationDialogService: ConfirmationDialogService
        ) { super(); }

    public ngOnInit(): void {
        this.loadingSpinner.begin();
        const crdNumber = this.route.snapshot.params.crdNumber;

        zip(this.userStore.profile, this.dataStore.getFirmSummary(crdNumber), this.dataStore.isSubscribedToFirm(crdNumber))
        .subscribe(([user, summaryRes, isSubscribed]: [UserProfile | undefined, FirmSummaryResult, boolean]) => {
            this.firmSummary = summaryRes;
            this.selectedFilingsDate = this.firmSummary.LastFilingDate;
            if (user) {
                const profile = user as UserProfile;
                this.canSubscribe = profile.Role === 'Analyst' ? false : true;
                this.isPremiumUser = user.IsPremium;
            }
            if (isSubscribed) {
                this.canSubscribe = false;
            }

            this.loadingSpinner.end();
        });
    }

    public openGoogleMapLink(url: string): void {
        window.open(url, '_blank');
    }

    public toggleCollapsedFund(event: any): void {
        const parentElement: HTMLElement = event.currentTarget.parentElement;
        if (parentElement) {
            if (parentElement.classList.contains('collapsed')) {
                parentElement.classList.remove('collapsed');
            } else {
                parentElement.classList.add('collapsed');
            }
        }
    }

    public gotoFirmADV(crdNumber: string): void {
        const url = this.router.serializeUrl(this.router.createUrlTree(['/advform', crdNumber, this.firmSummary.LastFilingDate]));
        window.open(url, '_blank');
    }

    public gotoFilingDetails(crdNumber: string): void {
        this.router.navigate(['/home/filingdetails', crdNumber]);
    }

    public navigateToFundSummary(crdNumber: string, secNumber: string): void {
        this.router.navigate(['/home/reports/fund', crdNumber, secNumber]);
    }

    public scrollToAlertsSection(): void {
        const querySelector = document.querySelector('#alerts-section');
        if (querySelector) {
            querySelector.scrollIntoView();
        }
    }

    public navigateToFirmSummary(crdNumber: string, filingDate: Date): void {
        this.loadingSpinner.begin();
        this.dataStore.getFirmSummaryByFilingDate(crdNumber, filingDate).subscribe((firmSummary: FirmSummaryResult) => {
            this.firmSummary = firmSummary;
            this.loadingSpinner.end();
        });
    }

    public onOptionsSelected(ev: any) {	
        const value = ev.target.value;	
       // this.selected = value;	
    	
     }

    public getRAUMString(raum: number): string {
        if (raum > 1000000000) {
            return `$${(raum / 1000000000).toFixed(2)} Billion`;
        } else if (raum > 1000000) {
            return `$${(raum / 1000000).toFixed(2)} Million`;
        }
        return `${raum}`;
    }

    public subscribeToFirm(): void {
        const crdNumber = this.route.snapshot.params.crdNumber;
        this.loadingSpinner.begin();
        this.dataStore.addFirmByCrd(crdNumber)
            .subscribe(() => {
                this.subscribed = true;
                this.canSubscribe = false;
                this.loadingSpinner.end();
            }, (error: HttpErrorResponse) => {
                this.loadingSpinner.end();
                if (error.status === 409) {
                    this.modalManager.error('Cannot Subscribe to Fund Manager. ' +
                    'Your subscription has reached the maxiumum number of distinct fund manager assignments.');
                }
            });
    }

    public get pdfMargin(): PageMargin {
        const margins: PageMargin = {
          left: '0.5cm',
          right: '0.5cm',
          top: '2cm',
          bottom: '1cm'
        };
        return margins;
    }

    public savePdf(): void {
        if (this.pdf != null) {
          this.actionsContainer.nativeElement.className = 'col-md-6 actions-container d-none';
          const modalRef = this.modalService.open(DocumentLoadingModalComponent, { windowClass: 'pdf-loading-modal-container'});
          modalRef.componentInstance.modalType = ModalType.PDF;

          this.pdf.export().then((group: Group) => {
            exportPDF(group).then((dataUri: string) => {
              this.pdfDataLink.nativeElement.href = dataUri;
              this.pdfDataLink.nativeElement.download = `firmsummary-${this.firmSummary.RegistrationInformation.CrdNumber}`;
              this.pdfDataLink.nativeElement.click();
              this.actionsContainer.nativeElement.className = 'col-md-6 actions-container';
              this.modalService.dismissAll();
            });
          });
        }
    }
    public unlockAlerts():void{
        this.confirmationDialogService.confirm(
            'To Unlock Alerts',
            '',
            true
            );
    }
}
