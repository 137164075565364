import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UserProfile } from '../_api/entities/user-profile';
import { UserStore } from './user/user.store';

@Injectable()
export class IsPremiumGuard implements CanActivate {
    constructor(private userStore: UserStore) { }

    public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.userStore.profile.onceDefined((profile: UserProfile) => {
                resolve(profile.IsPremium);
            });
        });
    }
}
