import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { LoadingSpinner } from '../../_concurrency/spinner/loading-spinner';
import { DataStore } from '../../_form-adv/data/data.store';
import { FirmFilingDetailsResponse } from 'src/app/_api/entities/firm-filing-details';

@Component({ templateUrl: 'firm-filing-details.component.html', styleUrls: ['../../../assets/css/dashboard.scss'] })
export class FirmFilingDetailsComponent extends SubscriberEntity implements OnInit {

    public firmFilingDetails!: FirmFilingDetailsResponse;
    public searchText = '';

    constructor(
        private route: ActivatedRoute,
        private dataStore: DataStore,
        public loadingSpinner: LoadingSpinner
    ) { super(); }

    public ngOnInit(): void {

        const crdNumber = this.route.snapshot.params.crdNumber;
        this.loadingSpinner.begin();

        this.dataStore.getFirmFilingDetails(crdNumber).subscribe((firmFilingDetails: FirmFilingDetailsResponse) => {
            this.firmFilingDetails = firmFilingDetails;
            this.loadingSpinner.end();
        });
    }
}
