import { Component, Input, OnChanges, Pipe, PipeTransform, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardAlert, DashboardFirm, DashboardFirmNew,  } from 'src/app/_api/entities/alert-data';
import { LoadingSpinner } from 'src/app/_concurrency/spinner/loading-spinner';
import { DataStore } from 'src/app/_form-adv/data/data.store';
import { DataService } from '../services/data.service';

@Pipe({
    name: 'highlight'
})
export class HighlightSearch implements PipeTransform {

    transform(value: string, args: string): any {
        if (args && value) {
            let startIndex = value.toLowerCase().indexOf(args.toLowerCase());
            if (startIndex != -1) {
                let endLength = args.length;
                let matchingString = value.substr(startIndex, endLength);
                return value.replace(matchingString, "<mark>" + matchingString + "</mark>");
            }
    
        }
        return value;
    }
}

@Component({
    selector: 'app-firm-card',
    templateUrl: 'firm-card-component.html',
    styleUrls: ['../../../assets/css/firm-card.scss']
})
export class FirmCardComponent implements OnChanges , OnInit{

    @Input('firm') public firm: DashboardFirmNew | undefined;
    @Input('searchText') public searchText = '';

    public isTextFiltered = false;
    public showAll = false;
    public showArchiveAllDialog = false;
    //public static crdNumber: number;

    constructor(
        private router: Router,
        private dataStore: DataStore,
        private dataService: DataService,
        private loadingSpinner: LoadingSpinner) {
    }

    ngOnInit(){
        this.dataStore.alertDashboardScrollPosition.subscribe(scrollPosition => {
            const scrollOptions = <ScrollOptions>{
                left: 0,
                top: scrollPosition ? scrollPosition : 0,
                behavior: 'auto'
            }
            window.scrollTo(scrollOptions);
        });
    }

    public ngOnChanges(changes: import ('@angular/core').SimpleChanges): void {
        this.isTextFiltered = this.isFiltered(this.searchText);
    }

    public gotoAlertDetail(AlertId: string): void {
        if(this.firm) {
            localStorage.setItem('firmId', this.firm.Id);
        }
        this.router.navigate(['home/alert', AlertId]);
    }

    public gotoFirmADV(crdNumber: string, filingDate: Date): void {
        const url = this.router.serializeUrl(this.router.createUrlTree(['/advform', crdNumber, filingDate ]));
        window.open(url, '_blank');
    }

    public gotoFirmSummary(crdNumber: string): void {
        const url = this.router.serializeUrl(this.router.createUrlTree(['/home/reports/firm', crdNumber]));
        window.open(url, '_blank');
    }

    private isFiltered(searchText: string): boolean {
        searchText = searchText.toLocaleLowerCase();
        if (searchText === '') { return true; }

        if (this.firm) {

            // Fund Manager
        if (this.firm.Name.toLowerCase().indexOf(searchText) > -1) { return true; }
        
        if (this.firm.CrdNumber.toLowerCase().indexOf(searchText) > -1) { return true; }

           return false;
        }
        return false;
    }

    public fundNameSearch(fundName: string) {
        if(!fundName || this.searchText === '') return;
        var containsSearchText = fundName.toLowerCase().indexOf(this.searchText) > -1
        let styles = {'background-color': containsSearchText ? '#fcf8e3' : '#f9f9f9'};
        return styles;
    }

    public gotoFirmWorkspace(firmdata: any): void {
        //FirmCardComponent.crdNumber = firmdata.CrdNumber;
        // this.dataService.changeFirmData(firmdata, this.searchText);
        this.dataStore.setAlertDashboardScrollPosition(window.pageYOffset);
        this.router.navigate(['/home/firm-workspace/firmworkspace', firmdata.CrdNumber]);
     }
}
