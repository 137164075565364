import { Component } from "@angular/core";

@Component({ templateUrl: './accept-terms-modal.component.html' })
export class AcceptTermsModal {
    public acceptTerms = false;
    public acceptMarketing = false;
    public readyForPurchase?: boolean;

    public setAcceptance(): void {
        if (this.acceptTerms) {
            this.readyForPurchase = this.acceptMarketing;
        } else {
            this.readyForPurchase = undefined;
        }
    }
}
