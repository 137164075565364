import { Component, Input, OnInit } from '@angular/core';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-radio-condition',
    templateUrl: 'advform-radio-condition.component.html',
    styleUrls: ['./advform-radio.component.scss']
})
export class AdvformRadioConditionComponent implements OnInit {

    @Input('formObj') public formObj: any;
    @Input('referenceString') public referenceString = '';
    @Input('condition') condition = false;
    @Input('expectedValue') public expectedValue = '';
    @Input('radioName') public radioName!: string;

    public checked = false;

    constructor(
        private advFormDetailService: AdvFormDetailService
    ) {}

    public ngOnInit(): void {
        const value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);

        if (value !== '' && this.condition && value === this.expectedValue ||
            value !== '' && !this.condition && value !== this.expectedValue) {
            this.checked = true;
        }
    }
}
