import {Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormAdvRoutes, Route } from '../../app/_config/routes';

@Component({ templateUrl: './landing-page.component.html' })
export class LandingPageComponent {
    constructor(
        private router: Router) {}

    public login(): void {
        this.router.navigate(['/home/dashboard']);
    }
}