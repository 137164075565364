import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalManager, SubscriberEntity } from '@concurrency/angular';
import { combineLatest, zip } from 'rxjs';
import { UserProfile } from '../_api/entities/user-profile';
import { DataStore } from '../_form-adv/data/data.store';
import { TitleService } from '../_form-adv/data/title.service';
import { ErrorService } from '../_form-adv/error/error.service';
import { UserStore } from '../_form-adv/user/user.store';
import { AcceptTermsModal } from './modals/accept-terms-modal.component';

@Component({ templateUrl: './home.component.html' })
export class HomeComponent extends SubscriberEntity implements OnInit {
    public userProfile!: UserProfile;
    public clientConfigAccess!: boolean;
    public title?: string;
    public showUser = false;
    public isTrial = false;

    public isDashboardRoute = false;
    public isReportsRoute = false;
    public isAdminRoute = false;
    public hamburgerOpen = false;

    public isStickyNav = false;
    public currentYear = 2022;

    private stickyNavScrollToggle = 150; // how many pixels must be scrolled to toggle sticky nav elements
    constructor(
        private dataStore: DataStore,
        private userStore: UserStore,
        private titleService: TitleService,
        private modalManager: ModalManager,
        private errorService: ErrorService
    ) { super(); }

    public ngOnInit(): void {

        window.onscroll = this.onScroll;

        zip(this.userStore.currentUser, this.userStore.profile).subscribe(([user, profile]) => {
            if (user && profile) {
                this.errorService.handleInvalidUser(user, profile);

                if (user.hasAccess) {
                    this.dataStore.init();

                    if (profile.AcceptTerms === false) {
                        this.requireTermsAndConditions();
                    }
                    this.userProfile = profile;
                    this.clientConfigAccess = this.userProfile.Role === 'Analyst' ? false : true;

                    let name = `${profile.FirstName || ''} ${profile.LastName || ''}`;
                    if (name === ' ') {
                        name = '';
                    }

                    this.titleService.set(`<div>${name}</div><div>${profile.Email}</div>`, true);

                    combineLatest(this.titleService.title, this.titleService.showUser).subscribe(([title, showUser]) => {
                        if (title && showUser) {
                            this.title = title;
                            this.showUser = showUser;
                        }
                    });
                }
            }
        });
        const url = window.location.href.toLocaleLowerCase();
        this.isDashboardRoute = url.includes('dashboard');
        this.isReportsRoute = url.includes('reports');
        this.isAdminRoute = url.includes('admin');
        this.currentYear = this.currentYearLong();
    }

    public logout(): void {
        this.userStore.logoutUserOrEmployee();
    }

    public reload(): void {
        window.location.reload();
    }

    public requireTermsAndConditions(): void {
        const modal = this.modalManager.open<boolean, AcceptTermsModal>(AcceptTermsModal, { mayDismiss: false, size: 'lg' });
        modal.once((acceptMarketing: boolean) => this.userStore.acceptTerms(acceptMarketing));
    }

    public updateRouteFlags(dashboard: boolean, reports: boolean, admin: boolean): void {
        this.dataStore.setAlertDashboardScrollPosition(0);
        this.isDashboardRoute = dashboard;
        this.isReportsRoute = reports;
        this.isAdminRoute = admin;
    }

    public toggleHamburgerOpen(): void {
        this.hamburgerOpen = !this.hamburgerOpen;
    }

    private onScroll = (): void => {
        this.isStickyNav = window.pageYOffset > this.stickyNavScrollToggle;
    }
    
    currentYearLong(): number {
        return new Date().getFullYear();
        }
}
