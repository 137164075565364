import { Component, Input, OnInit } from '@angular/core';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-radio-matchtext',
    templateUrl: 'advform-radio-matchtext.component.html',
    styleUrls: ['./advform-radio.component.scss']
})
export class AdvformRadioMatchTextComponent implements OnInit {

    @Input('matchText') public matchText: string | undefined;
    @Input('referenceString') public referenceString = '';
    @Input('formObj') public formObj: any;
    @Input('radioName') public radioName = '';

    public checked = false;

    constructor(private advFormDetailService: AdvFormDetailService) {}

    public ngOnInit(): void {
        const value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);

        if (value === this.matchText) {
            this.checked = true;
        }
    }
}
