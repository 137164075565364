import { Component, Input, OnInit } from '@angular/core';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-radiolabel',
    templateUrl: 'advform-radiolabel.component.html',
    styleUrls: ['./advform-radiolabel.component.scss']
  })
  export class AdvformRadioLabelComponent implements OnInit {

    @Input('labelText') public labelText: string | undefined;
    @Input('matchText') public matchText: string | undefined;
    @Input('referenceString') public referenceString = '';
    @Input('formObj') public formObj: any;
    @Input('radioName') public radioName = '';

    public selectedTextValue = '';
    public value : any;

    constructor(private advFormDetailService: AdvFormDetailService) {}

    public ngOnInit(): void {
      this.value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);
      if(this.value ==='')
      this.selectedTextValue='-1';
      else
      this.selectedTextValue = this.value;
    }
  }
