﻿import { Injectable } from '@angular/core';
import { UserStore, UserType, CurrentUser } from './user/user.store';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, UrlSegment } from '@angular/router';

@Injectable()
export class IsSubscriberGuard implements CanActivate {
    constructor(
        private router: Router,
        private userStore: UserStore
    ) { }

    // TODO: Why does this only work with a promise and not an Observable?
    // see: https://stackoverflow.com/questions/41131476/emptyerror-no-elements-in-sequence
    public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            this.userStore.inititializeAuthentication();
            this.userStore.currentUser.onceDefined((currentUser : CurrentUser) => {

                this.handleDeepLinkingToAlerts(currentUser.hasAccess, route);

                if (currentUser.hasAccess === true) {
                   return resolve(true);
                } else {
                    this.userStore.lastLoginType.subscribe((lastLoginType) => {
                        if (lastLoginType === UserType.Employee) {
                            this.router.navigate(['employee']);
                        } else {
                            this.router.navigate(['customer']);
                        }
                        resolve(false);
                    });
                }
            });
        });
    }

    // Allows the User to be re-directed to a specific Alert after authenticating.
    private handleDeepLinkingToAlerts(hasAccess: boolean, route: ActivatedRouteSnapshot): void {
        if(hasAccess === true) {
            const crdNumber = localStorage.getItem('crdNumber');
            const filingDate = localStorage.getItem('filingDate');
            const alertId = localStorage.getItem('alertId');
            if(alertId && alertId !== null && crdNumber!== null && filingDate!==null ) {
                localStorage.removeItem('crdNumber');
                localStorage.removeItem('filingDate');
                localStorage.removeItem('alertId');
                this.router.navigate(['/home/alert',crdNumber, filingDate,alertId]);
            }
        } else {
            const fromEmailParam = route.queryParams["fromEmail"];
            const crdNumber = route.firstChild ? route.firstChild.params['crdNumber'] : undefined;
            const filingDate = route.firstChild ? route.firstChild.params['filingDate'] : undefined;
            const alertId = route.firstChild ? route.firstChild.params['alertId'] : undefined;
            if(fromEmailParam === 'true' && alertId && crdNumber && filingDate ) {
                localStorage.setItem('crdNumber', crdNumber);
                localStorage.setItem('filingDate', filingDate);
                localStorage.setItem('alertId', alertId);
            }
        }
    }
}
