import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AdvformDetailData } from 'src/app/_api/entities/advform-data';
import { AdvFormDetailService } from '../services/advform-detail-service.component';

@Component({
    selector: 'advform-item9',
    templateUrl: './advform-item9.component.html',
    styleUrls: ['../../advform/advform.component.scss']
})
export class AdvformItem9Component implements OnInit {
    private collapseToggle: boolean = false;

    @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
    @Input('detail') public detail: AdvformDetailData | undefined;

    public sd_9_Cs = new Array<any>();

    constructor(public advFormDetailService: AdvFormDetailService) { }

    public ngOnInit(): void {
        this.sd_9_Cs = this.advFormDetailService.getDeepObjectArray(this.detail, 'ScheduleD.SD_9_C');
    }

    public toggleCollapseEvent() {
        this.collapseToggle = !this.collapseToggle;
        this.collapseEvent.emit(this.collapseToggle);
    }
}
