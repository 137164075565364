import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { LoadingSpinner } from 'src/app/_concurrency/spinner/loading-spinner';
import { AdminView, AlertSetting } from '../../_api/entities/admin';
import { DataStore } from '../../_form-adv/data/data.store';

@Component({
    templateUrl: 'admin.component.html',
    styleUrls: ['../../../assets/css/admin.scss']
})
export class AdminComponent extends SubscriberEntity implements OnInit {
    public ClientSettings!: AdminView;

    constructor(
        private router: Router,
        private dataStore: DataStore,
        public loadingSpinner: LoadingSpinner
    ) { super(); }

    public ngOnInit(): void {

        this.loadingSpinner.begin();

        this.dataStore.clientSettings.subscribe((data: AdminView | undefined) => {
            if(!data) {
                this.dataStore.getAdminView().once();
            }
        });

        this.dataStore.clientSettings.whileDefined((data: AdminView) => {
            this.ClientSettings = data;
            this.ClientSettings.Users = this.ClientSettings.Users.sort((a, b) =>
            {
                if(!a.FirstName) return 1;
                if(!b.FirstName) return -1;
                return a.FirstName.localeCompare(b.FirstName);
            });
            this.loadingSpinner.end();
        });
    }

    public updateSetting(setting: AlertSetting, option: string): void {
      if (setting.RequireAcknowledge === false && option === 'Acknowledge') {
        setting.RequireComment = false;
      }

      this.loadingSpinner.begin();
      this.dataStore.updateClientNotification(setting).subscribe(() => {
        this.loadingSpinner.end();
      });
    }

    public gotoUserDetail(userId: string): void {
        this.router.navigate(['home/admin/userdetail', userId]);
    }

    public gotoCreateUser(): void {
        this.router.navigate(['home/admin/create-user']);
    }

    public toggleActive(userId: string, active: boolean): void {
        this.loadingSpinner.begin();
        let request = new Observable<void>();
        if (!active) {
            request = this.dataStore.deactivateUser(userId);
        } else {
            request = this.dataStore.activateUser(userId);
        }
        request.subscribe(() => {
            this.loadingSpinner.end();
        });
    }
}
