import { Component, Input, OnInit } from '@angular/core';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-checkbox-label-group',
    templateUrl: 'advform-checkbox-label-group.component.html',
    styleUrls: ['./advform-checkbox-label-group.component.scss']
})
export class AdvformCombinationCheckboxLabelGroupComponent implements OnInit {

    @Input('checkBoxArrayLeft') public checkBoxArrayLeft = new Array<string>();
    @Input('checkBoxArrayRight') public checkBoxArrayRight = new Array<string>();
    @Input('referenceString') public referenceString = '';
    @Input('formObj') public formObj: any;

    private advObjStringArray = new Array<string>();

    constructor(
        private advFormDetailService: AdvFormDetailService
    ) { }

    public ngOnInit(): void {
        const value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);

        if (value) {
            this.advObjStringArray = value.split(';');

        }
    }

    /* TODO value is being pulled from array inputs instead of actual value in object*/
    public stringHasCurrency(value: string): boolean {
        return this.advObjStringArray.filter((advString) => {
            return advString.indexOf('$') > -1 && advString.indexOf(value) > -1;
        }).length > 0;
    }

    public retrieveCurrency(value: string): string {
        const currencyString = this.advObjStringArray.filter((advString) => {
            return advString.indexOf('$') > -1 && advString.indexOf(value) > -1;
        })[0];

        return currencyString.slice(currencyString.indexOf('$'), currencyString.length).replace(/[^$\d.,-]/g, ' ');
    }

    public retrieveCheckBoxTitle(value: string): string {
        const currencyIndex = value.indexOf('$');

        if (currencyIndex > -1) {
            return value.slice(0, value.indexOf('$'));
        }

        return value;
    }
}
