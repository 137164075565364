import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AdvformDetailData } from '../../_api/entities/advform-data';
import { AdvFormDetailService } from '../services/advform-detail-service.component';

@Component({
  selector: 'advform-brochures',
  templateUrl: './advform-brochures.component.html',
  styleUrls: ['../../advform/advform.component.scss']
})

export class AdvformBrochuresComponent implements OnInit{
  private collapseToggle = false;

  @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
  @Input('detail') public detail: AdvformDetailData | undefined;

    constructor(public advHelper: AdvFormDetailService){}

    public Part2BrochureData = new Array<any>();

    public ngOnInit(): void{
      this.Part2BrochureData = this.advHelper.getDeepObjectArray(this.detail, 'Part2BrochureData');
    }

    public toggleCollapseEvent(): void {
      this.collapseToggle = !this.collapseToggle;
      this.collapseEvent.emit(this.collapseToggle);
  }
}