import { AppModule } from './app/app.module';
import { ConcurrencyAuthenticator } from '@concurrency/authentication';
import { aadSettings, b2cSettings } from './app/_config/authentication.configuration';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// const authHandler = new AuthenticationHandler();
// const handledAuth = authHandler.handleAuthentication();
// if (handledAuth === false) {
//     bootstrapApp();
// }

const windowIdentity = ConcurrencyAuthenticator.initializeWindow(b2cSettings, aadSettings);
if (windowIdentity === 'MainWindow') {
    bootstrapApp();
} else {
    // TODO: Display progress indicator and/or Timeout after some duration
}

function bootstrapApp(): void {
    // The severe performance degradation caused by non-prod mode Angular 6
    //    has proven that it is not worth keeping this off in non-prod environments
    enableProdMode();

    // if (ng.environment.production) {
    //     ng.enableProdMode();
    // }

    platformBrowserDynamic().bootstrapModule(AppModule);
}
