import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'advform-radio-exactvalue',
    templateUrl: 'advform-radio-exactvalue.component.html',
    styleUrls: ['./advform-radio.component.scss']
})
export class AdvformRadioExactValueComponent implements OnInit {

    @Input('value') public value!: string;
    @Input('exactValue') public exactValue!: string;
    @Input('radioName') public radioName = '';

    public checked = false;

    constructor() { }

    public ngOnInit(): void {
        if (this.value === this.exactValue) {
            this.checked = true;
        }
    }
}
