import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient, AuthenticationManager, EnvironmentManager, HttpOptions, NetworkManager } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { CreateUserFormModel } from 'src/app/home/admin/models/create-user-form-model';
import { UpdateUserFormModel } from 'src/app/home/admin/models/update-user-form-model';
import { environment } from 'src/environments/environment';
import { AddCommentRequest } from '../entities/add-comment-request';
import { AdminView, AlertSetting, Firm } from '../entities/admin';
import { AlertData,AlertDataNew, AlertDetail, DashboardAlert, FirmWorkspaceResponse, FirmAlertData, DashboardFiling } from '../entities/alert-data';
import { ArchiveAlertRequest } from '../entities/archive-alert-request';
import { ExportDashboardRequest } from '../entities/export-dashboard-request';
import { AlertDetailsResponse, FirmFilingDetailsResponse } from '../entities/firm-filing-details';
import { FirmsReportsSearchResult, FirmSummaryResult, FundsReportsSearchResult, FundSummaryResult, FirmFiling } from '../entities/reports';

@Injectable()
export class FormADVService extends ApiClient {

    constructor(
        protected environmentManager: EnvironmentManager,
        private httpOverride: HttpClient,
        private authenticationManagerOverride: AuthenticationManager,
        private networkManagerOverride: NetworkManager
    ) {
        super(environmentManager, httpOverride, authenticationManagerOverride, networkManagerOverride);
    }

    protected baseUrl = `${environment.urls.apiUrl}/api`;
    // Alerts
    public getAlerts(): Observable<AlertData> {
        return this.get('dashboard');
    }

    public getAlertsNew(): Observable<AlertDataNew> {
        return this.get('dashboard/DashboardNew');
    }

    public exportAlerts(exportRequest: ExportDashboardRequest): Observable<any> {
        const tokenReq = this.authenticationManagerOverride.getToken();
        return tokenReq.once((token: string) => {
            const headers = new HttpHeaders().set('authorization', 'Bearer ' + token);
            return this.httpOverride.post(this.baseUrl + '/dashboard/export', exportRequest, {headers, responseType: 'blob' as 'json'});
        });
    }

    public getAlert(AlertId: string): Observable<AlertDetail> {
        return this.get(`alerts/${AlertId}`);
    }

    public acknowledgeAlert(req: ArchiveAlertRequest): Observable<void> {
        return this.post(`clientAlert/acknowledge`, req);
    }

    public addComment(req: AddCommentRequest): Observable<void> {
        return this.post(`clientAlert/comment`, req);
    }

    public archiveAlerts(FirmId: string): Observable<void> {
        return this.put(`clientalert/firm/${FirmId}/archive`, {});
    }

    public getDashboardAlertDetail(clientAlertId: string): Observable<DashboardAlert> {
        return this.get(`clientalert/${clientAlertId}`);
    }

    // Admin
    public getAdminView(): Observable<AdminView> {
        return this.get('admin');
    }

    public updateNotificationConfig(AlertSettings: AlertSetting): Observable<void> {
      return this.post(`admin/update`, AlertSettings);
    }

    public updateAnalystDirectorRelationship(analystId: string, directorId: string): Observable<void> {
        return this.post(`admin/relationship/analyst/${analystId}/director/${directorId}`, {});
    }

    public copyAnalystsToNewDirector(directorFromId: string, directorToId: string): Observable<void> {
        return this.post(`admin/copy/directorFrom/${directorFromId}/directorTo/${directorToId}`, {});
    }

    // User Detail
    public addFirm(userId: string, FirmId: string): Observable<void> {
        return this.patch(`admin/user/${userId}/firm/${FirmId}`, {});
    }

    public subscribeToFirm(firmCrd: string): Observable<void> {
        return this.patch(`admin/firm/${firmCrd}/subscribe`, {});
    }

    public removeFirm(userId: string, FirmId: string): Observable<void> {
        return this.delete(`admin/user/${userId}/firm/${FirmId}`, {});
    }

    public copyFirms(sourceUserId: string, TargetUserId: string): Observable<void> {
        return this.patch(`admin/copyFirmSubscriptions?sourceUserId=${sourceUserId}&targetUserId=${TargetUserId}`, {});
    }

    public activateUser(userId: string): Observable<void> {
        return this.post(`admin/user/${userId}/enable`, null);
    }

    public deactivateUser(userId: string): Observable<void> {
        return this.post(`admin/user/${userId}/disable`, null);
    }

    public createUser(userModel: CreateUserFormModel): Observable<void> {
        return this.post(`admin/user`, userModel);
    }

    public updateUser(userModel: UpdateUserFormModel): Observable<void> {
        return this.post('admin/user', userModel);
    }

    // Firms
    public searchFirms(searchText: string): Observable<Firm[]> {
        return this.get('firm?searchText=' + searchText);
    }

    public isSubscribedToFirm(crdNumber: string): Observable<boolean> {
        return this.get(`firm/subscribed/${crdNumber}`);
    }

    // Reports
    public searchReportFirms(searchText: string): Observable<FirmsReportsSearchResult> {
        return this.get('firmdata/SearchFirms?searchText=' + searchText);
    }

    public searchReportFunds(searchText: string): Observable<FundsReportsSearchResult> {
        return this.get('firmdata/SearchFunds?searchText=' + searchText);
    }

    public getFundSummary(crdNumber: string, secNumber: string): Observable<FundSummaryResult> {
        return this.get(`firmdata/firm/${crdNumber}/fund/${secNumber}`);
    }

    public getFundSummaryByFilingDate(crdNumber: string, secNumber: string, filingDate: Date): Observable<FundSummaryResult> {
        return this.get(`firmdata/firm/${crdNumber}/fund/${secNumber}/${filingDate}`);
    }

    public getFirmSummary(crdNumber: string): Observable<FirmSummaryResult> {
        return this.get(`firmdata/firm/${crdNumber}`);
    }

    public getFirmSummaryByFilingDate(crdNumber: string, filingDate: Date): Observable<FirmSummaryResult> {
        return this.get(`firmdata/firm/${crdNumber}/${filingDate}`);
    }

    // Firm Filing Details

    public getFirmFilingDetails(crdNumber: string): Observable<FirmFilingDetailsResponse> {
        return this.get(`firm/filingdetails/${crdNumber}`);
    }
  
    public getFormAdv(crdNumber: string, filingDate: string): Observable<any> {
        return this.get(`FirmData/FormAdv/Firm/${crdNumber}/${filingDate}`);
    }

    public getAlertDetails(alertDataId: string): Observable<AlertDetailsResponse> {
        return this.get(`alerts/details/${alertDataId}`);
    }
    
    public getFirmWorkspace(crdNumber: string): Observable<AlertData> {
        return this.get(`firm/firmworkspace/${crdNumber}`);
    }

    public getFirmWorkspaceByFillingDate(crdNumber: string, filingDate: Date): Observable<AlertData> {
        return this.get(`firm/firmworkspace/${crdNumber}?#${filingDate}`);
    }

    public getFilingDates(crdNumber: string): Observable<FirmFiling[]> {
        return this.get(`firm/firmfilingdates/${crdNumber}`);
    }
    public getFirmByFilingDate(crdNumber: string, filingDate: Date): Observable<DashboardFiling[]> {
      return this.get(`firm/firmfilingdetailsbydate/${crdNumber}/${filingDate}`);
    }
}
