import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'retrieveNestedObjValue'
 })
export class RetrieveNestedObjPipe implements PipeTransform {
    public transform(obj: any, path: string): string {
        return path.split('.').reduce((fragment, prop) => fragment[prop], obj);
    }
}
