import { Injectable } from '@angular/core';
import { UserStore, CurrentUser } from './user/user.store';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class IsNotSubscriberGuard implements CanActivate {
    constructor(
        private router: Router,
        private userStore: UserStore
    ) { }

    // TODO: Why does this only work with a promise and not an Observable?
    // see: https://stackoverflow.com/questions/41131476/emptyerror-no-elements-in-sequence
    public canActivate(): Promise<boolean> {
        return new Promise((resolve) => {
            this.userStore.currentUser.onceDefined((currentUser: CurrentUser) => {
                if (currentUser.hasAccess === false) {
                    return resolve(true);
                } else {
                    this.router.navigate(['']);
                    resolve(false);
                }
            });
            this.userStore.inititializeAuthentication();
        });
    }
}
