import { Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationManager } from '@concurrency/angular';
import { Authentication, AuthenticationType } from '@concurrency/authentication';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/_api/services/user-profile.service';
import { UserProfile } from 'src/app/_api/entities/user-profile';
import { LoadingSpinner } from 'src/app/_concurrency/spinner/loading-spinner';

export interface CurrentUser {
    hasAccess: boolean;
    type: UserType;
}

export enum UserType {
    None,
    Commercial = 'Commercial',
    Employee = 'Employee'
}

@Injectable()
export class UserStore {
    private _currentUser = new BehaviorSubject<CurrentUser | undefined>(undefined);
    private _profile = new BehaviorSubject<UserProfile | undefined>(undefined);
    private _lastLoginType = new BehaviorSubject<UserType | undefined>(undefined);
    public profile = this._profile.asObservable();
    public currentUser = this._currentUser.asObservable();
    public lastLoginType = this._lastLoginType.asObservable();

    constructor(
        private authenticationManager: AuthenticationManager,
        private loadingSpinner: LoadingSpinner,
        private userProfileService: UserProfileService,
        private router: Router
    ) { }

    public inititializeAuthentication(): void {
        this.authenticationManager.authentication.whileDefined((authentication: Authentication) => {
            this.findUser(authentication);
            if (authentication.previous === AuthenticationType.AAD) {
                this._lastLoginType.next(UserType.Employee);
            } else if (authentication.previous === AuthenticationType.B2C) {
                this._lastLoginType.next(UserType.Commercial);
            }
        });
    }

    private findUser(authentication: Authentication): void {
        if (authentication.current === AuthenticationType.None) {
            this._currentUser.next({ hasAccess: false, type: UserType.None });
        } else {
            const profileRequest = this.userProfileService.read();
            this.loadingSpinner.begin();
            profileRequest.onceDefined((profile: UserProfile) => {

                const hasSubscription = profile && new Date(profile.SubscriptionEndDate) >= new Date();
                if(hasSubscription) {
                    this._currentUser.next({ hasAccess: true, type: UserType.Commercial });
                } else {
                    if(authentication.current === AuthenticationType.AAD) {
                        this._currentUser.next({ hasAccess: true, type: UserType.Employee });
                    } else {
                        this._currentUser.next({ hasAccess: false, type: UserType.Commercial });
                    }
                }

                this._profile.next(profile);
                this.loadingSpinner.end();
            });
        }
    }

    public loginEmployee(): void {
        this.authenticationManager.loginAAD();
    }

    public loginUser(): void {
        const params = {['app-title']: 'formadv'};
        this.authenticationManager.loginB2C(params);
    }

    public logoutUserOrEmployee(): void {
        this.loadingSpinner.begin();
        this.authenticationManager.forget();
        this.authenticationManager.logoutB2C();
    }

    // TODO: Why is this in here, subscribing to this class?
    public acceptTerms(marketingOptIn: boolean): Observable<UserProfile> {
        return this.profile.once((profile) => {
            return this.userProfileService.acceptTerms(marketingOptIn);
        });
    }
}
