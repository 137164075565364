import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { combineLatest } from 'rxjs';
import { AlertDataNew, AlertGroup, AlertSetting, DashboardAlertNew,  DashboardFirmNew, DashboardFilingNew, AlertGroupNew } from '../../_api/entities/alert-data';
import { UserProfile } from '../../_api/entities/user-profile';
import { selectMany } from '../../_concurrency/helper-functions';
import { LoadingSpinner } from '../../_concurrency/spinner/loading-spinner';
import { DataStore } from '../../_form-adv/data/data.store';
import { UserStore } from '../../_form-adv/user/user.store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardExcelExportComponent } from './dashboard-excel-export.component';


@Component({ templateUrl: 'dashboard.component.html', styleUrls: ['../../../assets/css/dashboard.scss'] })
export class DashboardComponent extends SubscriberEntity implements OnInit {

    public dashboardData!: DashboardFirmNew[];
    public currentUser!: UserProfile;
    public clientSettings!: AlertSetting[];
    public searchText = '';
    public showAll = false;

    constructor(
        private userStore: UserStore,
        private dataStore: DataStore,
        private excelExportModal: NgbModal,
        public loadingSpinner: LoadingSpinner
    ) { super();
        if(this.dataStore.isAlertAcknowledged)
        {
            console.log("Alert Acknowledged ")
            this.dataStore.refreshAlertDataNew();
            this.dataStore.isAlertAcknowledged = false;
        }
    }

    public ngOnInit(): void {
      
        const setupUser = (profile: UserProfile) => {
            const name = `${profile.FirstName || ''} ${profile.LastName || ''}`.trim();
        };
        this.dataStore.isAlertAcknowledged = false;
        this.userStore.profile.whileDefined(setupUser);

        const request = combineLatest([this.userStore.profile, this.dataStore.alertsNew]);
        request.subscribe((data: [UserProfile | undefined, AlertDataNew | undefined]) => {
            const user = data[0];
            const alerts = data[1];

            if (user != null && alerts != null) {
                this.currentUser = user;
                alerts.AlertData.sort((a, b) => a.Name.localeCompare(b.Name));
                alerts.AlertData.forEach((firm) => {

                    firm.FilingCollection.sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());

                    const allAlerts = selectMany(firm.FilingCollection, (f: DashboardFilingNew) => f.Alerts);
                    allAlerts.sort((a: DashboardAlertNew, b: DashboardAlertNew) => {
                        return a.Severity - b.Severity ;
                    });

                        const groupedAlerts: AlertGroupNew[] = [
                        { Sort: 1,  Severity: 3,
                             Count: allAlerts.filter((i) => i.Severity === 3).reduce((accum,item) => accum + item.Count, 0) ,
                             Label: `Critical Updates (${allAlerts.filter((i) => i.Severity === 3).reduce((accum,item) => accum + item.Count, 0)})`},
                        { Sort: 2,  Severity: 2,
                            Count: allAlerts.filter((i) => i.Severity === 2).reduce((accum,item) => accum + item.Count, 0),
                            Label: `Important Updates (${allAlerts.filter((i) => i.Severity === 2).reduce((accum,item) => accum + item.Count, 0)})` },
                        { Sort: 3, Severity: 1,
                           Count: allAlerts.filter((i) => i.Severity === 1).reduce((accum,item) => accum + item.Count, 0),
                           Label: `Notable Updates (${allAlerts.filter((i) => i.Severity === 1).reduce((accum,item) => accum + item.Count, 0)})`}
                    ];

                     firm.DashboardAlerts = groupedAlerts;
                });

                this.dashboardData = alerts.AlertData.sort((a: DashboardFirmNew, b: DashboardFirmNew) => {
                    return new Date(b.FilingCollection[0].Date).getTime() - new Date(a.FilingCollection[0].Date).getTime() || a.Name.localeCompare(b.Name);
                });
                this.loadingSpinner.end();
            }
        });
    }

    public ngAfterViewInit(): void {
    }
    
    public showExcelExportFlyOut(): void {
        const modalRef = this.excelExportModal.open(DashboardExcelExportComponent, { windowClass: 'flyout-right', backdrop: 'static', keyboard: false });
        modalRef.componentInstance.dashboardData = this.dashboardData;
        modalRef.componentInstance.selectAllFirms = true;
        modalRef.componentInstance.showSelectedFirm = false;
    }
}
