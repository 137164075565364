import { Injectable } from '@angular/core';
import { EventManager } from '../event.manager';
import { BehaviorSubject, Observable, pipe, of } from 'rxjs';
import { AlertData,AlertDataNew, AlertDetail, DashboardFirm,DashboardFirmNew, FirmWorkspaceResponse, FirmAlertData, DashboardAlert, DashboardFiling, AlertCaretStatus } from 'src/app/_api/entities/alert-data';
import { AdminView, AlertSetting, Firm } from 'src/app/_api/entities/admin';
import { UserProfile } from 'src/app/_api/entities/user-profile';
import { FormADVService } from 'src/app/_api/services/form-adv-data.service';
import { selectMany } from 'src/app/_concurrency/helper-functions';
import { CreateUserFormModel } from 'src/app/home/admin/models/create-user-form-model';
import { UpdateUserFormModel } from 'src/app/home/admin/models/update-user-form-model';
import { ArchiveAlertRequest } from 'src/app/_api/entities/archive-alert-request';
import { FundSummaryResult, FirmSummaryResult, FirmsReportsSearchResult, FundsReportsSearchResult, FirmFiling } from 'src/app/_api/entities/reports';
import { FirmFilingDetailsResponse, AlertDetailsResponse } from 'src/app/_api/entities/firm-filing-details';
import { AddCommentRequest } from 'src/app/_api/entities/add-comment-request';
import { map, filter } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';


@Injectable()
export class DataStore extends EventManager {
    private _alerts = new BehaviorSubject<AlertData | undefined>(undefined);
    private _alertsNew = new BehaviorSubject<AlertDataNew | undefined>(undefined);
    private _alertDetail = new BehaviorSubject<AlertDetail | undefined>(undefined);
    private _clientSettings = new BehaviorSubject<AdminView | undefined>(undefined);
    private _userDetail = new BehaviorSubject<UserProfile | undefined>(undefined);
    private _alertDashboardScrollPosition = new BehaviorSubject<number | undefined>(undefined);

    public alerts = this._alerts.asObservable();
    public alertsNew = this._alertsNew.asObservable();
    public alertdetail = this._alertDetail.asObservable();
    public clientSettings = this._clientSettings.asObservable();
    public userDetail = this._userDetail.asObservable();
    public alertDashboardScrollPosition = this._alertDashboardScrollPosition.asObservable();
    public isAlertAcknowledged = false;
    public dashbordAlertData : AlertData | undefined;
    public selectedFilingsDate : any;
    public  isAlertModifed = false;
    public  modifiedAlerts: string[]=[];
    public selectedFirmAlerts : any;
    constructor(
        private formADVService: FormADVService
    ) { super(); }

    public init(): void {
      //  this.refreshAlertData();
        this.refreshAlertDataNew();
        this.getAdminView();
    }

    // Alerts
    public refreshAlertData(): Observable<void> {
        console.log('refreshAlertData called');
        const request = this.formADVService.getAlerts();
        return request.once((data) => {
            this._alerts.next(data);
        });
    }
    public refreshAlertDataNew(): Observable<void> {
        const request = this.formADVService.getAlertsNew();
        return request.once((data) => {
            this._alertsNew.next(data);
        });
    }
   
    public getFirmDetails(crdNumber: string): Observable<AlertData> {
       // const existing = this.selectedFirmAlerts as AlertData ; 
       const existing = this.dashbordAlertData  ; 
       let result: any;
       if( this.dashbordAlertData && existing && existing.CrdNumber === crdNumber){
            result = this.alerts.pipe(map((alts: any) => {
                  return this.dashbordAlertData;
             }));
            } else {
             return result = this.getFirmWorkspace(crdNumber);
        }
       return result;
    }
    public getAlertDetail(alertId: string): Observable<void> {

        if(this.dashbordAlertData!=null){
            const alertSetting = this.dashbordAlertData.AlertSettings;

            const filingArray = selectMany(this.dashbordAlertData.AlertData, (firm: DashboardFirm) => firm.FilingCollection);
            const alertArray = selectMany(filingArray, (filing) => filing.Alerts);
            const alert = alertArray.find((a) => a.Id === alertId);
            const filing = filingArray.find((f) => f.Alerts.some((a) => a.Id === alertId));
            const firm = this.dashbordAlertData.AlertData.find((i) => i.FilingCollection.some((f) => (filing !== undefined) && f.Id === filing.Id));
           
            if (alert && filing && firm) {
                let severityIcon = '';
                let severityDescription = '';

                switch (alert.Severity) {
                    case 3: { severityIcon = 'fa-exclamation-triangle'; severityDescription = 'Critical'; break; }
                    case 2: { severityIcon = 'fa-exclamation-circle'; severityDescription = 'Medium'; break; }
                    case 1: { severityIcon = 'fa-check-circle'; severityDescription = 'Low'; break; }
                    default: { }
                }

                const dasboardAlertReq = this.formADVService.getDashboardAlertDetail(alertId);
                return dasboardAlertReq.once((dashboardAlert) => {
                    return this._alertDetail.next({
                        Firm: firm.Name,
                        Users: firm.AssignedUsers,
                        Date: filing.Date,
                        Alert: dashboardAlert,
                        SeverityIcon: severityIcon,
                        SeverityTitle: severityDescription,
                        AlertSettings: alertSetting
                    });
                });
            }
            else {
                const request = this.formADVService.getAlert(alertId);
                return request.once((alertData) => {
                    this._alertDetail.next(alertData);
                });
        
        }
    }
    return of();
    }
    
    public acknowledgeAlert(AlertDetail: AlertDetail, comment: string): Observable<void> {
        const dto = {} as ArchiveAlertRequest;
        dto.AlertId = AlertDetail.Alert.Id;
        dto.Reason = comment;
        const request = this.formADVService.acknowledgeAlert(dto as ArchiveAlertRequest);
        // return request.once(() => this.getAlertDetail(AlertDetail.Alert.Id));
        return request.once(() => this.refreshFirmAlertData(AlertDetail));
    }
    public addComment(AlertDetail: AlertDetail, comment: string): Observable<void> {
        const dto = {} as AddCommentRequest;
        dto.ClientAlertId = AlertDetail.Alert.Id;
        dto.Comment = comment;

        const request = this.formADVService.addComment(dto as AddCommentRequest);
        return request.once(() => this.getAlertDetail(AlertDetail.Alert.Id));
         // return request.once(() => this.refreshFirmAlertData(AlertDetail));
    }

    public archiveAlerts(FirmId: string): Observable<void> {
        const request = this.formADVService.archiveAlerts(FirmId);
        return request.once(() => this.refreshAlertData());
    }
    private refreshFirmAlertData(AlertDetail: AlertDetail) {
        if (this.dashbordAlertData != null) {
          //  const filing= this.dashbordAlertData.AlertData[0].FilingCollection.find(f=>f.Date === AlertDetail.Date)?.Alerts.find(o => o.Id === AlertDetail.Alert.Id);

        const filingindx= this.dashbordAlertData.AlertData[0].FilingCollection.findIndex(f=>f.Date === AlertDetail.Date);
        if(filingindx >-1)
        {
            const alertIndex = this.dashbordAlertData.AlertData[0].FilingCollection[filingindx].Alerts.findIndex(o => o.Id === AlertDetail.Alert.Id);
            if (alertIndex !== -1) {
                this.dashbordAlertData.AlertData[0].FilingCollection[filingindx].Alerts[alertIndex] = AlertDetail.Alert;
            }
        }
        }
    }
    // Admin View
    public getAdminView(): Observable<void> {
        const request = this.formADVService.getAdminView();
        return request.once((data: AdminView) => {
            this._clientSettings.next(data);
        });
    }

    public updateClientNotification(alertSetting: AlertSetting): Observable<void> {
        const request = this.formADVService.updateNotificationConfig(alertSetting);
        return request.once(() => this.getAdminView().once(() => this.refreshAlertData()));
    }

    public updateAnalystDirectorRelationship(analystId: string, directorId: string): Observable<void> {
        const request = this.formADVService.updateAnalystDirectorRelationship(analystId, directorId);
        return request.once(() => this.getAdminView());
    }

    public copyAnalystsToNewDirector(directorFromId: string, directorToId: string): Observable<void> {
        const request = this.formADVService.copyAnalystsToNewDirector(directorFromId, directorToId);
        return request.once(() => this.getAdminView());
    }

    // User Details
    public selectUserDetail(userId: string): Observable<void> {
        return this.clientSettings.whileDefined((data: AdminView) => {
            let user = data.Users.find((u) => u.Id === userId);
            if (user) {
                this._userDetail.next(user);
            }
        });
    }

    public addFirm(userId: string, firmId: string): Observable<void> {
        const request = this.formADVService.addFirm(userId, firmId);
        return request.once(() => this.getAdminView());
    }

    public addFirmByCrd(firmCrd: string): Observable<void> {
        const request = this.formADVService.subscribeToFirm(firmCrd);
        return request.once(() => this.getAdminView());
    }

    public removeFirm(userId: string, firmId: string): Observable<void> {
        const request = this.formADVService.removeFirm(userId, firmId);
        return request.once(() => this.getAdminView());
    }

    public copyFirms(sourceUserId: string, targetUserId: string): Observable<void> {
        const request = this.formADVService.copyFirms(sourceUserId, targetUserId);
        return request.once(() => this.getAdminView());
    }

    public deactivateUser(userId: string): Observable<void> {
        const request = this.formADVService.deactivateUser(userId);
        return request.once(() => this.getAdminView());
    }

    public activateUser(userId: string): Observable<void> {
        const request = this.formADVService.activateUser(userId);
        return request.once(() => this.getAdminView());
    }

    public createUser(userModel: CreateUserFormModel): Observable<void> {
        const request = this.formADVService.createUser(userModel);
        return request.once(() => this.getAdminView());
    }

    public updateUser(userModel: UpdateUserFormModel): Observable<void> {
        const request = this.formADVService.updateUser(userModel);
        return request.once(() => this.getAdminView());
    }

    // Firms
    public searchFirms(searchText: string): Observable<Firm[]> {
        const request = this.formADVService.searchFirms(searchText);
        return request;
    }

    public isSubscribedToFirm(crdNumber: string): Observable<boolean> {
        const request = this.formADVService.isSubscribedToFirm(crdNumber);
        return request;
    }

    // Reports
    public searchReportFirms(searchText: string): Observable<FirmsReportsSearchResult> {
        const request = this.formADVService.searchReportFirms(searchText);
        return request;
    }

    public searchReportFunds(searchText: string): Observable<FundsReportsSearchResult> {
        const request = this.formADVService.searchReportFunds(searchText);
        return request;
    }

    public getFundSummary(crdNumber: string, secNumber: string): Observable<FundSummaryResult> {
        const request = this.formADVService.getFundSummary(crdNumber, secNumber);
        return request;
    }

    public getFundSummaryByFilingDate(crdNumber: string, secNumber: string, filingDate: Date): Observable<FundSummaryResult> {
        const request = this.formADVService.getFundSummaryByFilingDate(crdNumber, secNumber, filingDate);
        return request;
    }

    public getFirmSummary(crdNumber: string): Observable<FirmSummaryResult> {
        const request = this.formADVService.getFirmSummary(crdNumber);
        return request;
    }

    public getFirmSummaryByFilingDate(crdNumber: string, filingDate: Date): Observable<FirmSummaryResult> {
        const request = this.formADVService.getFirmSummaryByFilingDate(crdNumber, filingDate);
        return request;
    }

    // Firm Filing Details
    public getFirmFilingDetails(crdNumber: string): Observable<FirmFilingDetailsResponse> {
        const request = this.formADVService.getFirmFilingDetails(crdNumber);
        return request;
    }
  
    public getAlertDetails(alertDataId: string): Observable<AlertDetailsResponse> {
        const request = this.formADVService.getAlertDetails(alertDataId);
        return request;
    }
    public getFirmWorkspace(crdNumber: string): Observable<AlertData> {
        const request = this.formADVService.getFirmWorkspace(crdNumber);
        request.once((data) => {
            this._alerts.next(data);
        });
        return request;
    }
    public getFilingDates(crdNumber: string): Observable<FirmFiling[]> {
        const request = this.formADVService.getFilingDates(crdNumber);
        return request;
    }
    public getFirmByFilingDate(crdNumber: string, filingDate: Date): Observable<DashboardFiling[]> {
        const request = this.formADVService.getFirmByFilingDate(crdNumber, filingDate);
        return request;
    }
    public getFirmWorkspaceByFillingDate(crdNumber: string, filingDate: Date): Observable<AlertData> {
        const request = this.formADVService.getFirmWorkspaceByFillingDate(crdNumber, filingDate);
        return request;
    }
    public getFirmByFilingDate2(crdNumber: string, filingDate: Date): Observable<DashboardFiling[]> {
        const request = this.formADVService.getFirmByFilingDate(crdNumber, filingDate);
        return request;
    }
    public setAlertDashboardScrollPosition(scrollPosition:  number) {
        this._alertDashboardScrollPosition.next(scrollPosition);
    }

    public setAlertCaretStatus(alertCaretStatus : AlertCaretStatus) {
        let _alertCaretStatusArr = localStorage.getItem('alertCaretStatuses');
        let alertCaretStatusArr: AlertCaretStatus[];

        if(!_alertCaretStatusArr) {
            alertCaretStatusArr = [];
        } else {
            alertCaretStatusArr = JSON.parse(_alertCaretStatusArr);
        }
        
        if(Array.isArray(alertCaretStatusArr)){
            const index = alertCaretStatusArr.findIndex( as => as.crdNumber === alertCaretStatus.crdNumber && as.severity === alertCaretStatus.severity);
            if(index !== -1) {
                alertCaretStatusArr[index].isCollapsed =  alertCaretStatus.isCollapsed;
            } else {
                alertCaretStatusArr.push(alertCaretStatus);
            }
            localStorage.setItem('alertCaretStatuses', JSON.stringify(alertCaretStatusArr));
        }
    }

    public getAlertCaretStatus(crdNumber: string) : AlertCaretStatus[] {
        let alertCaretStatusArr = localStorage.getItem('alertCaretStatuses');
        if(alertCaretStatusArr) {
            alertCaretStatusArr = JSON.parse(alertCaretStatusArr);
            if(Array.isArray(alertCaretStatusArr)){
                return alertCaretStatusArr.filter( as => as.crdNumber === crdNumber);
            }
        }
        return [];
    }

}
