import { AuthenticationManager, HttpError, ModalManager, NetworkManager, SubscriberEntity } from '@concurrency/angular';
import { Component, OnInit } from '@angular/core';
import { LoadingSpinner } from './_concurrency/spinner/loading-spinner';
import { UserStore } from './_form-adv/user/user.store';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({ selector: 'app-root', templateUrl: './app.component.html' })
export class AppComponent extends SubscriberEntity implements OnInit {
    constructor(
        public loadingSpinner: LoadingSpinner,
        private modalManager: ModalManager,
        private userStore: UserStore,
        private authenticationManager: AuthenticationManager,
        private networkManager: NetworkManager,
        private router: Router
    ) {
        super();

        this.authenticationManager.error.whileDefined((error: HttpError) => {
            this.userStore.logoutUserOrEmployee();
        });

        this.networkManager.error.whileDefined((error: HttpError) => {
            if (error.status === 409) {
                this.networkManager.acknowledgeErrors();
                this.loadingSpinner.end();
                return;
            }
            if (error.status === 403) {
                this.networkManager.acknowledgeErrors();
                this.loadingSpinner.end();
                window.open(environment.urls.buyLicense, '_self');
                return;
            }
                        
            this.loadingSpinner.end();
            this.modalManager
                .error(error.status === 404 ? error.error : 'Network Error has occured. Please refer to you admin for assistance')
                .subscribe(() => this.networkManager.acknowledgeErrors());
        });

        this.authenticationManager.initialize();
    }

    public ngOnInit(): void {
        this.scrollToTopOnRoute();
    }

    private scrollToTopOnRoute(): void {
        this.router.events.subscribe((evt: any) => {
            if (evt instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });
    }
}
