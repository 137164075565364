import { Component, Input } from "@angular/core";
import { DRP } from 'src/app/_api/entities/reports';

@Component( {
    templateUrl: 'reports-firm-drp-component.html', 
    styleUrls:['../../../assets/css/reports.scss'],
    selector: 'app-firm-drp'
})
export class ReportsFirmDrpComponent {
    @Input() drps: DRP[]= new Array<DRP>();
    constructor(){}
}