import { AfterViewChecked, AfterViewInit, Component, HostListener, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurroundingAnchors } from '../_api/entities/surrounding-anchors';
import { TrackedAnchorDetail } from '../_api/entities/tracked-anchor-detail';
import { navigationItems } from './navigation-items';
import { trackedAnchorIds } from './tracked-anchor-ids';

@Component({
  selector: 'advform-nav',
  templateUrl: './advform-nav.component.html',
  styleUrls: ['./advform-nav.component.scss']
})
export class AdvformNavComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {
  public navigationItems = navigationItems;
  public trackedAnchorIds = trackedAnchorIds;
  public urlPathName = window.location.pathname;

  @Input('recalculateContainers') public recalculateContainers: boolean | undefined = undefined;

  private fragment: any;
  private isScrolling = false;
  private trackedAnchorDetails = new Array<TrackedAnchorDetail>();

  private activeAnchors = {} as SurroundingAnchors;

  constructor(
    private activeRoute: ActivatedRoute
  ) {}

  @HostListener('window:resize', [])
  public onResize(): void {
    this.resizeSideNavContainer();
    this.setTrackedAnchorDetails();
  }

  @HostListener('window:scroll', [])

  public onWindowScroll(): void {
    const sideNav = document.querySelector('.formadv-side-nav');
    const scrollPosition: number = parseInt(window.pageYOffset.toFixed());
    const offSetAmount = 5;

    const closestAnchor: TrackedAnchorDetail = this.trackedAnchorDetails.filter((anchorDetail) => {
      return scrollPosition >= (anchorDetail.topPosition - window.innerHeight) + offSetAmount &&
             scrollPosition <= anchorDetail.bottomPosition - offSetAmount ||
             scrollPosition === 0;
    })[0];

    this.isScrolling = true;

    if (sideNav) {

      if (!sideNav.classList.contains('fixed-nav') && scrollPosition > 285) {
        sideNav.classList.add('fixed-nav');
      } else if (sideNav.classList.contains('fixed-nav') && scrollPosition < 285) {
        sideNav.classList.remove('fixed-nav');
      }

      if (closestAnchor) {

        this.resetClassesForNextAnchor(closestAnchor);

        const surroundingAnchors = {
          topThirdNavItem: {
            ...this.trackedAnchorDetails[closestAnchor.index - 3],
            navItemClasses: 'nav-item nav-border-nobottom'
          },
          topSecondNavItem: {
            ...this.trackedAnchorDetails[closestAnchor.index - 2],
            navItemClasses: 'nav-item nav-small-protruding nav-border-top nav-border-nobottom'
          },
          topClosestNavItem: {
            ...this.trackedAnchorDetails[closestAnchor.index - 1],
            navItemClasses: 'nav-item nav-medium-protruding nav-border-top nav-border-nobottom'
          },
          closestNavItem: {
            ...closestAnchor,
            navItemClasses: 'nav-item nav-large-protruding nav-item-active nav-border-top'
          },
          bottomClosestNavItem: {
            ...this.trackedAnchorDetails[closestAnchor.index + 1],
            navItemClasses: 'nav-item nav-medium-protruding nav-border-notop'
          },
          bottomSecondNavItem: {
            ...this.trackedAnchorDetails[closestAnchor.index + 2],
            navItemClasses: 'nav-item nav-small-protruding'
          }
        };

        this.activeAnchors = surroundingAnchors;

        for (const [key, value] of Object.entries(surroundingAnchors)) {
          if (value) {
            const navItem = document.getElementById(`side-nav-${value.id}`);
            if (navItem) {
              navItem.className = value.navItemClasses;
            }
          }
        }

      }
    }
  }

  public ngOnInit(): void {
    this.activeRoute.fragment.subscribe((fragment) => {
      this.fragment = fragment;
      this.isScrolling = false;
    });
  }

  public ngAfterViewInit(): void {
    this.resizeSideNavContainer();
    this.setTrackedAnchorDetails();
  }

  public ngAfterViewChecked(): void  {
    if (this.fragment && !this.isScrolling) {
      const hashFragment = document.querySelector(`${this.fragment}`);

      if (hashFragment) {
        hashFragment.scrollIntoView();
        this.onWindowScroll();
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.recalculateContainers.currentValue === true ||
        changes.recalculateContainers.currentValue === false) {
        const componentThis = this;

        setTimeout(() => {
          componentThis.resizeSideNavContainer();
          componentThis.setTrackedAnchorDetails();
        }, 500);
    }
  }

  private setTrackedAnchorDetails(): void  {
    this.trackedAnchorDetails = new Array<TrackedAnchorDetail>();

    this.trackedAnchorIds.forEach((anchorId, index) => {
      const anchorElement = document.getElementById(anchorId);

      if (anchorElement) {
        const elementCoordinates = anchorElement.getBoundingClientRect();

        this.trackedAnchorDetails = [...this.trackedAnchorDetails, {
          id: anchorId,
          index,
          topPosition: parseInt((elementCoordinates.top + window.pageYOffset).toFixed()),
          bottomPosition: parseInt((elementCoordinates.bottom + window.pageYOffset).toFixed())
        }];
      }
    });
  }

  private resizeSideNavContainer(): void {
    const sideNavContainer = document.querySelector('.formadv-side-nav-container');

    if (sideNavContainer) {
      const sideNavParentWidth = `${sideNavContainer.getBoundingClientRect().width}px`;
      const formSideNav = document.querySelector('.formadv-side-nav') as HTMLElement;

      if (formSideNav && sideNavParentWidth) {
        formSideNav.style.width = sideNavParentWidth;
      }
    }
  }

  private resetClassesForNextAnchor(closestAnchor: TrackedAnchorDetail): void {
    if (this.activeAnchors.closestNavItem && closestAnchor.id !== this.activeAnchors.closestNavItem.id) {

      Object.values(this.activeAnchors).forEach((navItemSignature) => {
        if (navItemSignature && navItemSignature.id) {
          document!.getElementById(`side-nav-${navItemSignature.id}`)!.className = 'nav-item';
        }
      });

    }
  }
}
