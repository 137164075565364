﻿import { Injectable } from '@angular/core';
import { UserStore } from './user/user.store';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class CanCreateProfileGuard implements CanActivate {
    constructor(
        private router: Router,
        private userStore: UserStore
    ) { }

    // TODO: Why does this only work with a promise and not an Observable?
    // see: https://stackoverflow.com/questions/41131476/emptyerror-no-elements-in-sequence
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // this.userStore.isSubscriber.subscribe(isSubscriber => {
                // if (isSubscriber) {
                    resolve(true);
                // } else {
                    // this.router.navigate(['landing']);
                    // resolve(false);
                // }
            // });
        });
    }
}
