import { NgModule } from '@angular/core';
import { LoadingSpinner } from '../_concurrency/spinner/loading-spinner';
import { CommonModules } from '../_config/common-modules';
import { ErrorService } from '../_form-adv/error/error.service';
import {AdvformDetailComponent} from '../advform-detail/advform-detail.component';
import { AdvformDRPComponent } from '../advform-detail/drps/advform-drp.component';
import {AdvformItem1Component} from '../advform-detail/item1/advform-item1.component';
import { AdvformItem1BComponent } from '../advform-detail/item1/advform-item1b.component';
import { AdvformItem10Component } from '../advform-detail/item10/advform-item10.component';
import {AdvformItem11Component} from '../advform-detail/item11/advform-item11.component';
import { AdvformItem12Component } from '../advform-detail/item12/advform-item12.component';
import {AdvformItem2Component} from '../advform-detail/item2/advform-item2.component ';
import {AdvformItem2cComponent} from '../advform-detail/item2/advform-item2c.component';
import {AdvformItem3Component} from '../advform-detail/item3/advform-item3.component';
import { AdvformItem4Component } from '../advform-detail/item4/advform-item4.component';
import { AdvformItem5Component } from '../advform-detail/item5/advform-item5.component';
import { AdvformItem5Section5Component } from '../advform-detail/item5/advform-item5section5.component';
import {AdvformItem6Component} from '../advform-detail/item6/advform-item6.component';
import { AdvformItem7Component } from '../advform-detail/item7/advform-item7.component';
import { AdvformItem8Component } from '../advform-detail/item8/advform-item8.component';
import { AdvformItem9Component } from '../advform-detail/item9/advform-item9.component';
import {AdvformScheduleAComponent} from '../advform-detail/schedule_a/advform-schedulea.component';
import { AdvformScheduleBComponent } from '../advform-detail/schedule_b/advform-scheduleb.component';
import { AdvformScheduleDComponent } from '../advform-detail/schedule_d/advform-scheduled.component';
import { AdvformScheduleRComponent } from '../advform-detail/schedule_r/advform-scheduler.component';
import { AdvFormDetailService } from '../advform-detail/services/advform-detail-service.component';
import {AdvformNavComponent} from '../advform-nav/advform-nav.component';
import {AdvformComponent} from './advform.component';
import { AdvFormValueComponent } from './components/advform-value.component';
import { AdvFormCheckCommaTextComponent } from './components/checkbox/advform-check-commatext.component';
import { AdvformCheckValueComponent } from './components/checkbox/advform-check-value.component';
import {AdvformCheckComponent} from './components/checkbox/advform-check.component';
import { AdvformCombinationCheckboxLabelGroupComponent } from './components/combinations/advform-checkbox-label-group.component';
import { AdvFormJudgmentPart2Number5Component } from './components/combinations/advform-judgment-part2-number5.component';
import { AdvformCombinationLabelLabelComponent } from './components/combinations/advform-label-label.component';
import { AdvformCombinationRadioLabelTextareaComponent } from './components/combinations/advform-label-radio-textarea.component';
import { AdvformCombinationRadioLabelComponent } from './components/combinations/advform-radio-label.component';
import { AdvFormLabelComponent } from './components/label/advform-label-component';
import { AdvformRadioConditionComponent } from './components/radio/advform-radio-condition.component';
import { AdvformRadioExactValueComponent } from './components/radio/advform-radio-exactvalue.component';
import { AdvformRadioMatchTextComponent } from './components/radio/advform-radio-matchtext.component';
import { AdvformRadioValueComponent } from './components/radio/advform-radio-value.component';
import { AdvformRadioYesNoComponent } from './components/radio/advform-radio-yesno.component';
import {AdvformRadioComponent} from './components/radio/advform-radio.component';
import {AdvformRadioLabelComponent} from './components/radio/advform-radiolabel.component';
import { AdvFormTextAreaComponent } from './components/textarea/advform-textarea.component';
import { AdvformSignaturePageComponent } from '../advform-detail/signature-page/advform-signaturepage.component';
import { DocumentLoadingModalComponent } from './document-loading-modal.component';
import { AdvformBrochuresComponent } from '../advform-detail/brochures/advform-brochures.component';


@NgModule({
    declarations: [
        AdvformComponent,
        AdvformDetailComponent,
        AdvformItem1Component,
        AdvformItem2Component,
        AdvformItem1BComponent,
        AdvformItem3Component,
        AdvformItem4Component,
        AdvformItem5Component,
        AdvformNavComponent,
        AdvformCheckComponent,
        AdvformCheckValueComponent,
        AdvformRadioComponent,
        AdvformRadioYesNoComponent,
        AdvformItem2cComponent,
        AdvformRadioLabelComponent,
        AdvformRadioValueComponent,
        AdvformRadioMatchTextComponent,
        AdvformRadioConditionComponent,
        AdvFormTextAreaComponent,
        AdvformItem5Section5Component,
        AdvformItem6Component,
        AdvformItem7Component,
        AdvformItem8Component,
        AdvformItem9Component,
        AdvformItem10Component,
        AdvformItem11Component,
        AdvformItem12Component,
        AdvformScheduleAComponent,
        AdvformScheduleBComponent,
        AdvformScheduleDComponent,
        AdvformScheduleRComponent,
        AdvformDRPComponent,
        AdvformSignaturePageComponent,
        AdvFormLabelComponent,
        AdvFormValueComponent,
        AdvFormCheckCommaTextComponent,
        AdvformRadioExactValueComponent,
        AdvformCombinationRadioLabelComponent,
        AdvformCombinationLabelLabelComponent,
        AdvformCombinationRadioLabelTextareaComponent,
        AdvformCombinationCheckboxLabelGroupComponent,
        AdvFormJudgmentPart2Number5Component,
        DocumentLoadingModalComponent,
        AdvformBrochuresComponent
    ],
    providers: [
        LoadingSpinner,
        ErrorService,
        AdvFormDetailService,
    ],
    imports: [
        ...CommonModules
    ],
    entryComponents: [
        DocumentLoadingModalComponent
    ]
})
export class AdvformModule { }
