import { NgxLoggerLevel } from "ngx-logger";
import { EnvironmentConfiguration } from "@concurrency/angular";
import { FormAdvRoutes, Route } from 'src/app/_config/routes';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: EnvironmentConfiguration = {
    production: false,
    name: 'dev',
    urls: {
        apiUrl: 'https://regulatoryclarity-dev-api.kroll.com',
        buyLicense: 'https://regulatoryclarity-dev.kroll.com/'+ FormAdvRoutes[Route.PlaceHolderBuyLicense],
        landingPage: 'https://regulatoryclarity-dev.kroll.com/'+ FormAdvRoutes[Route.PlaceHolderLandingPage],
    },
    applicationInsights: {
        name: 'dev-us-formadv-wa-01',
        key: 'e34f3d64-2a77-4999-be4e-782037670911'
    },
    loggerLevel: NgxLoggerLevel.TRACE,
    authentication: {
        aad: {
            client: '16a2837d-55fd-43fe-87e8-0d95e0acd866',
            tenant: '22f67706-36d3-482e-9bbc-0a5f5eeae757',
            endpoints: [
                'https://duffandphelps.onmicrosoft.com/22f67706-36d3-482e-9bbc-0a5f5eeae757',
                'bb2ca938-052f-4556-9b3c-1afbeaf50fbe'
            ],
            durationMinutes: 30
        },
        b2c: {
            provider: 'b2clogin.com',
            workflow: 'Redirect',
            client: 'a5a6e9bb-b4b2-442e-80e2-5bca1b78648d',
            redirectUri: 'https://regulatoryclarity-dev.kroll.com/login-redirect',
            tenant: 'devusdpb2c',
            scopes: ['https://devusdpb2c.onmicrosoft.com/formadvapi/read_write'],
            policies: {
                signup: 'B2C_1_signup',
                signin: 'B2C_1_signup_signin',
                signinSignup: 'B2C_1_signup_signin',
                forgotPassword: 'B2C_1_reset_password'
            }
        }
    }
};
