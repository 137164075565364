export enum Route {
    Employee,
    Customer,
    Contact,
    BuyNow,
    Home,
    Dashboard,
    DashboardExcelExport,
    Alert,
    Admin,
    UserDetail,
    Form,
    CreateUser,
    Reports,
    ReportsFirmSummary,
    ReportsFundSummary,
    FirmFilingDetails,
    FirmFilingDetailsAlert,
    ADVForm,
    PlaceHolderBuyLicense,
    PlaceHolderLandingPage,
    LoginRedirect,
    FirmWorkspace,
    EmailAlert
}

export const FormAdvRoutes: { [key: number ]: string } = {};
FormAdvRoutes[Route.Employee] = 'employee';
FormAdvRoutes[Route.Customer] = 'customer';
FormAdvRoutes[Route.Contact] = 'contact-us';
FormAdvRoutes[Route.BuyNow] = 'buy-now';
FormAdvRoutes[Route.Home] = 'home';
FormAdvRoutes[Route.Dashboard] = 'dashboard';
FormAdvRoutes[Route.Alert] = 'alert/:alertId';
FormAdvRoutes[Route.EmailAlert] = 'alert/:crdNumber/:filingDate/:alertId';
FormAdvRoutes[Route.Admin] = 'admin';
FormAdvRoutes[Route.UserDetail] = 'admin/userdetail/:userId';
FormAdvRoutes[Route.Form] = 'form';
FormAdvRoutes[Route.CreateUser] = 'admin/create-user';
FormAdvRoutes[Route.Reports] = 'reports';
FormAdvRoutes[Route.ReportsFirmSummary] = 'reports/firm/:crdNumber';
FormAdvRoutes[Route.ReportsFundSummary] = 'reports/fund/:crdNumber/:secNumber';
FormAdvRoutes[Route.FirmFilingDetails] = 'filingdetails/:crdNumber';
FormAdvRoutes[Route.FirmFilingDetailsAlert] = 'filingdetails/:crdNumber/alert/:alertId';
FormAdvRoutes[Route.ADVForm] = 'advform/:crdNumber/:filingDate';
FormAdvRoutes[Route.PlaceHolderBuyLicense] = 'content/buy-license';
FormAdvRoutes[Route.PlaceHolderLandingPage] = 'content/landing-page';
FormAdvRoutes[Route.LoginRedirect] = 'login-redirect';
FormAdvRoutes[Route.FirmWorkspace] = 'firm-workspace/firmworkspace/:crdNumber';