import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export enum ModalType {
  Unknown = 0,
  PDF = 1,
  Excel = 2
}

@Component({
    selector: 'document-loading-modal',
    template: `
      <div class="modal-header">
        <h4 *ngIf="modalType === ModalTypes.PDF" class="modal-title">Generating PDF</h4>
        <h4 *ngIf="modalType === ModalTypes.Excel" class="modal-title">Generating Spreadsheet</h4>
      </div>
      <div class="modal-body">
        <p>Please wait while your document is being generated.</p>
        <div class="pdf-loading-modal-icon">
            <i *ngIf="modalType === ModalTypes.PDF" class="fa fa-file-pdf-o"></i>
            <i *ngIf="modalType === ModalTypes.Excel" class="ms-Icon ms-Icon--ExcelDocument"></i>
        </div>
      </div>
    `,
    styleUrls: ['./advform.component.scss']
  })
  export class DocumentLoadingModalComponent {

    public ModalTypes = ModalType;
    public modalType: ModalType = ModalType.Unknown;

    constructor(public activeModal: NgbActiveModal) {}
  }