import { Component, Input, OnInit } from '@angular/core';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-judgment-part2-number5',
    templateUrl: 'advform-judgment-part2-number5.component.html',
    styleUrls: ['./advform-judgment-part2-number5.component.scss']
})
export class AdvFormJudgmentPart2Number5Component implements OnInit {

    @Input('referenceString') public referenceString = '';
    @Input('labelNote') public labelNote = '';
    @Input('formObj') public formObj: any;
    @Input('radioName') public radioName = '';

    public exactRadio = 'N';
    public explainationRadio = 'N';
    public textAreaValue = '';
    public labelValue = '';
    public fullWidth = false;

    constructor(private advFormDetailService: AdvFormDetailService) { }

    public ngOnInit(): void {
        const value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);

        if (value) {
            const valueSplit = value.split(', ');

            this.labelValue = valueSplit[0];

            if (valueSplit[1]) {
                this.explainationRadio = 'Y';
                this.textAreaValue = valueSplit[1];
            } else {
                this.exactRadio = 'Y';
            }

            if (this.textAreaValue && this.textAreaValue.length > 300) {
                this.fullWidth = true;
            }
        }
    }
}
