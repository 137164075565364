import {Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationManager } from '@concurrency/angular';
import { FormAdvRoutes, Route } from '../../app/_config/routes';

@Component({ templateUrl: './buy-license.component.html' })
export class BuyLicenseComponent {
    constructor(
        private authmanager: AuthenticationManager,
        private router: Router) {}

    public logout(): void {
        this.authmanager.logoutB2C();
        this.router.navigateByUrl(FormAdvRoutes[Route.Customer]);
    }
}
