import { Injectable } from '@angular/core';

@Injectable()

export class AdvFormDetailService {
    constructor() {}

    public getDeepObjectValue(root: any, path: string): string {
        const pathArr = path.split('.');
        let current = root;

        for (let i = 0; i < pathArr.length; i++) {
            if (current[pathArr[i]]) {
                current = current[pathArr[i]];

                if (i === pathArr.length - 1) {
                    break;
                }
            } else {
                return '';
            }
        }

        return current;
    }

    public getDeepObjectArray(root: any, path: string): any[] {
        const res = new Array<any>();

        const pathArr = path.split('.');
        let current = root;
        for (let i = 0; i < pathArr.length; i++) {
            if (current[pathArr[i]]) {
                current = current[pathArr[i]];
                if(i === pathArr.length - 1) {
                    break;
                }
            } else {
                return res;
            }
        }

        return current as any[];
    }

    public formatCurrency(root: any, path: string): string {

        const val = this.getDeepObjectValue(root, path);

        if (val === undefined || val == null || val === '') {
            return '';
        }

        const str: string[] = [];
        let cnt = 0;
        for (let i: number = val.length - 1; i >= 0; --i) {
            str.push(val[i]);
            ++cnt;
            if (cnt === 3) {
                cnt = 0;
                if (i > 0) {
                    str.push(',');
                }
            }
        }

        let res = '$';

        for (let j: number = str.length - 1; j >= 0; --j) {
            res += str[j];
        }

        return res;
    }

    //
    //  this field is 3 comma seperated values
    //  return 0,1,2 for the 3 individual fields
    public splitAddress(root: any, path: string, part: number): string {
        const val = this.getDeepObjectValue(root, path);

        if (val === undefined || val == null || val === '') {
            return '';
        }

        const parts: string[] = val.split(',');
        if (parts.length > part) {
            return parts[part];
        }

        return '';
    }

    // so this field is actually two values that are seperated by a space but may
    // or may not include a comma
    // get either the first value (0) or second valure (1)
    // and remove the comma if needed
    public splitColumn(root: any, path: string, part: number): string {
        const val = this.getDeepObjectValue(root, path);

        if (val === undefined || val == null || val === '') {
            return '';
        }

        const parts: string[] = val.split(' ');
        if (parts.length > part) {
            const res: string = parts[part].replace(',', '');
            return res;
        }

        return '';
    }
}
