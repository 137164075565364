import { Component, Input, OnInit } from '@angular/core';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-combination-label-label',
    templateUrl: 'advform-label-label.component.html',
    styleUrls: ['./advform-label-label.component.scss']
})
export class AdvformCombinationLabelLabelComponent implements OnInit {

    @Input('referenceString') public referenceString = '';
    @Input('secondLabelQuestion') public secondLabelQuestion = '';
    @Input('firstLabelValue_new') public firstLabelValue_new = '';
    @Input('secondLabelValue_new') public secondLabelValue_new = '';
    @Input('formObj') public formObj: any;

    public checked = false;
    public firstLabelValue = '';
    public secondLabelValue = '';

    constructor(private advFormDetailService: AdvFormDetailService) { }

    public ngOnInit(): void {
        const value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);
        const firstLabelValue_new = this.advFormDetailService.getDeepObjectValue(this.formObj, this.firstLabelValue_new);
        const secondvalue_new = this.advFormDetailService.getDeepObjectValue(this.formObj, this.secondLabelValue_new);


        if (value) {
            const valueSplit = value.split(/, (.+)/);

            this.firstLabelValue = valueSplit[0];
            this.secondLabelValue = valueSplit[1];
        } else {
            this.firstLabelValue = firstLabelValue_new;
            this.secondLabelValue =secondvalue_new;
        }

    }
}
