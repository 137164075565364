import { Component, Input, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-label',
    templateUrl: 'advform-label.component.html',
    styleUrls: ['advform-label.component.scss']
})
export class AdvFormLabelComponent extends SubscriberEntity implements OnInit {

    @Input('formObj') public formObj: any;
    @Input('referenceString') public referenceString = '';
    @Input('currency') public currency = '';
    @Input('explicitValue') public explicitValue = '';
    @Input('size') public size = 'med';    

    public displayText = '';
    public isSmallLabel = false;

    constructor(
        private advFormDetailService: AdvFormDetailService
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.size === 'sm') {
            this.isSmallLabel = true;
        }
        if (this.referenceString && this.referenceString !== '') {

            const value = (this.currency == 'y' ? 
            this.advFormDetailService.formatCurrency(this.formObj, this.referenceString):
            this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString));

            this.displayText = value;         
        } else if (this.explicitValue && this.explicitValue !== '') {
            this.displayText = this.explicitValue;
        }
    }

}
