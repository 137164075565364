import { NgModule } from '@angular/core';
import {ADVFormDataService} from '../_api/services/advform-data';
import { CanAccessGuard } from './can-access-guard';
import { CanCreateProfileGuard } from './can-create-profile.guard';
import { DataStore } from './data/data.store';
import { TitleService } from './data/title.service';
import { IsNotSubscriberGuard } from './is-not-subscriber.guard';
import { IsPremiumGuard } from './is-premium-guard';
import { IsSubscriberGuard } from './is-subscriber.guard';
import { UserStore } from './user/user.store';
const declarationsAndExports: any[] = [];

@NgModule({
    declarations: [...declarationsAndExports],
    entryComponents: [],
    exports: [...declarationsAndExports],
    imports: [
    ],
    providers: [
        UserStore,
        DataStore,
        ADVFormDataService,
        IsSubscriberGuard,
        IsNotSubscriberGuard,
        CanCreateProfileGuard,
        CanAccessGuard,
        IsPremiumGuard,
        TitleService
    ]
})
export class FormAdvModule { }
