import { Routes } from '@angular/router';
import * as re from './route-entities.barrel';
import { FormAdvRoutes, Route } from './routes';

/* Routing */
export const routeConfig: Routes = [

    { path: FormAdvRoutes[Route.Customer], component: re.CustomerLoginComponent, canActivate: [re.IsNotSubscriberGuard] },
    { path: FormAdvRoutes[Route.Employee], component: re.EmployeeLoginComponent, canActivate: [re.IsNotSubscriberGuard] },
    {
        path: FormAdvRoutes[Route.Home], component: re.HomeComponent, canActivate: [re.IsSubscriberGuard],
        children:
        [
            { path: FormAdvRoutes[Route.Dashboard], component: re.DashboardComponent },
            { path: FormAdvRoutes[Route.Alert], component: re.AlertComponent },
            { path: FormAdvRoutes[Route.Reports], component: re.ReportsSearchComponent },
            { path: FormAdvRoutes[Route.ReportsFirmSummary], component: re.ReportsFirmSummaryComponent},
            { path: FormAdvRoutes[Route.ReportsFundSummary], component: re.ReportsFundSummaryComponent},
            { path: FormAdvRoutes[Route.EmailAlert], component: re.AlertComponent },
            { path: FormAdvRoutes[Route.FirmWorkspace], component: re.FirmWorkspaceComponent },

            { path: FormAdvRoutes[Route.Admin], component: re.AdminComponent,
                canActivate: [re.CanAccessGuard],
                data: {permissions: ['Admin', 'Director', 'Duff & Phelps Admin']}
            },
            { path: FormAdvRoutes[Route.UserDetail], component: re.UserComponent,
                canActivate: [re.CanAccessGuard],
                data: {permissions: ['Admin', 'Director', 'Duff & Phelps Admin']}
            },
            { path: FormAdvRoutes[Route.CreateUser], component: re.CreateUserComponent,
                canActivate: [re.CanAccessGuard],
                data: {permissions: ['Admin', 'Director', 'Duff & Phelps Admin']}
            },
            { path: FormAdvRoutes[Route.FirmFilingDetails], component: re.FirmFilingDetailsComponent,
                canActivate: [re.CanAccessGuard, re.IsPremiumGuard],
                data: {permissions: ['Admin', 'Director', 'Duff & Phelps Admin', 'Analyst']}
            },
            { path: FormAdvRoutes[Route.FirmFilingDetailsAlert], component: re.FirmFilingDetailsAlertComponent,
                canActivate: [re.CanAccessGuard, re.IsPremiumGuard],
                data: {permissions: ['Admin', 'Director', 'Duff & Phelps Admin', 'Analyst']}
            }
        ]
    },
    { path: FormAdvRoutes[Route.ADVForm], component: re.AdvformComponent, canActivate: [] },
    { path: FormAdvRoutes[Route.PlaceHolderBuyLicense], component: re.BuyLicenseComponent},
    { path: FormAdvRoutes[Route.PlaceHolderLandingPage], component: re.LandingPageComponent},
    { path: FormAdvRoutes[Route.LoginRedirect], component: re.LoginRedirectComponent },
    { path: FormAdvRoutes[Route.ADVForm], component: re.AdvformComponent, canActivate: [] },
    { path: '', component: re.LandingComponent },
    { path: '**', redirectTo: FormAdvRoutes[Route.Dashboard] }
];
