import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class TitleService {
    private _title = new BehaviorSubject<string|undefined>(undefined);
    private _showUser = new BehaviorSubject<boolean|undefined>(undefined);
    public title = this._title.asObservable();
    public showUser = this._showUser.asObservable();

    public set(title: string, showUser: boolean = false): void {
        this._title.next(title);
        this._showUser.next(showUser);
    }
}
