import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

@Injectable()
export class LoadingSpinner {
    public isActive = false;
    private shouldBegin = false;

    public begin(delay: number = 100): void {
        if (delay === 0) {
            this.isActive = true;
        } else {
            this.shouldBegin = true;
            setTimeout(() => {
                if (this.shouldBegin) {
                    this.isActive = true;
                }
            }, delay);
        }
    }

    public end(): Observable<void> {
        this.isActive = false;
        this.shouldBegin = false;

        return of(void 0);
    }

    // TODO: Implement "while" meaning while an observable has not returned
}
