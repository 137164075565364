import { Component, Input, OnChanges, Pipe, PipeTransform, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardAlert, DashboardFirm, FirmWorkspaceResponse, DashboardFiling, AlertGroup, FirmAlertData, AlertData,  } from 'src/app/_api/entities/alert-data';
import { LoadingSpinner } from 'src/app/_concurrency/spinner/loading-spinner';
import { DataStore } from 'src/app/_form-adv/data/data.store';
import { DataService } from '../services/data.service';
import { FirmSummaryResult, FirmFiling } from 'src/app/_api/entities/reports';
import { zip, combineLatest, Subscription } from 'rxjs';
import { UserStore } from 'src/app/_form-adv/user/user.store';
import { UserProfile } from 'src/app/_api/entities/user-profile';
import { SubscriberEntity } from '@concurrency/angular';
import { dateFormat } from 'highcharts';
import { selectMany } from 'src/app/_concurrency/helper-functions';
import { Location } from '@angular/common';
import{AlertComponent} from '../dashboard/alert.component';
import { DashboardExcelExportComponent } from '../dashboard/dashboard-excel-export.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { PlatformLocation } from '@angular/common'
import { AlertCaretStatus } from 'src/app/_api/entities/alert-data';


@Pipe({
    name: 'highlight'
})
export class HighlightSearch implements PipeTransform {

    transform(value: string, args: string): any {
        if (args && value) {
            let startIndex = value.toLowerCase().indexOf(args.toLowerCase());
            if (startIndex != -1) {
                let endLength = args.length;
                let matchingString = value.substr(startIndex, endLength);
                return value.replace(matchingString, "<mark>" + matchingString + "</mark>");
            }
        }
        return value;
    }
}

@Component({
  selector: 'firm-workspace',
  templateUrl: './firm-workspace.component.html',
 // styleUrls: ['./firm-workspace.component.scss']
 //styleUrls: ['../../../../assets/css/firm-card.scss']
 styleUrls: ['../../../assets/css/firm-card.scss']
})
export class FirmWorkspaceComponent extends SubscriberEntity implements OnChanges, OnInit, OnDestroy  {

public dashboardData!: DashboardFirm[];
 public firm: DashboardFirm | undefined;
 public searchText = '';
 public firmSummary!: FirmSummaryResult;
  public isTextFiltered = false;
  public showAll = false;
  public showArchiveAllDialog = false;
  public selectedFilingsDate : any;
  public crdNumber ='';
  public fillingDate : any;
  public firmFilings!: FirmFiling[];
  public isModified = false;
  public modifiedAlerts : string[] = [];
  private subscription: Subscription = new Subscription();
  private alertCaretStatus = <AlertCaretStatus>{};
  public alertCaretStatusArr: AlertCaretStatus[] = [];
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private dataStore: DataStore,
    private userStore: UserStore,
    private loadingSpinner: LoadingSpinner,
    private dataService: DataService,
    private excelExportModal: NgbModal
     ) { super(); }

  public ngOnInit(): void {
  this.crdNumber = this.route.snapshot.params.crdNumber;
  this.loadingSpinner.begin();
  this.isModified = this.dataStore.isAlertModifed;
  this.modifiedAlerts = this.dataStore.modifiedAlerts;
 
//  COMMENTED CODE WILL BE REMOVED IN FINAL CODE CHECKIN

  this.subscription.add(
  this.dataStore.getFirmDetails(this.crdNumber).subscribe((firmdata: AlertData) => {
            // firmdata.CrdNumber = this.crdNumber;
            this.loadingSpinner.begin();
            this.dataStore.dashbordAlertData = firmdata;
            this.processFirmData(firmdata);
            // this.selectedFilingsDate = this.firm?.FilingCollection[0].Date;
            this.isTextFiltered = this.isFiltered(this.searchText);
               // ------------------------------------
            this.loadingSpinner.end();
            })
    );

    this.alertCaretStatusArr =  this.dataStore.getAlertCaretStatus(this.crdNumber);
  }

  isAlertCollapsed(alertGroup: AlertGroup) {
    const alertCaretStatus = this.alertCaretStatusArr.find(as => as.severity === alertGroup.Severity && as.crdNumber === this.crdNumber);
    if (alertCaretStatus) {
        return alertCaretStatus.isCollapsed;
    }
    return false;
  }

  


    private processFirmData(firmdata: AlertData) {
        const alerts = firmdata;
        // tslint:disable-next-line: align
        if (alerts != null && alerts.AlertData) {
           // firmdata.CrdNumber = this.crdNumber;
            //    this.currentUser = user;
            // this.clientSettings = alerts.AlertSettings;
            let allAlerts: DashboardAlert[];
            this.dataStore.selectedFirmAlerts = firmdata;
            alerts.AlertData.sort((a, b) => a.Name.localeCompare(b.Name));
            alerts.AlertData.forEach((firm) => {

                firm.FilingCollection.sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
                const filing = firm.FilingCollection.find(f=>f.Date === this.dataStore.selectedFilingsDate);
                if(filing !== undefined)
                    {
                        allAlerts =  filing.Alerts;
                        this.selectedFilingsDate = filing.Date;
                        this.dataStore.selectedFilingsDate = filing.Date;
                    } else{
                        allAlerts =  firm.FilingCollection[0].Alerts;
                        this.selectedFilingsDate = firm.FilingCollection[0].Date;
                        this.dataStore.selectedFilingsDate = firm.FilingCollection[0].Date;
                        }
                //const allAlerts = selectMany(firm.FilingCollection, (f: DashboardFiling) => f.Alerts);

                allAlerts.sort((a: DashboardAlert, b: DashboardAlert) => {
                    return a.Severity - b.Severity || a.Title.localeCompare(b.Title);
                });
                const groupedAlerts: AlertGroup[] = this.getGroupedAlerts(allAlerts);

                firm.DashboardAlerts = groupedAlerts;

                let enableArchive = false;
                alerts.AlertSettings.forEach((setting) => {
                    if (setting.RequireAcknowledge === false && allAlerts.filter((i) => i.Severity === setting.Id).length > 0) {
                        enableArchive = true;
                    }
                });
                firm.EnableArchive = enableArchive;
                //firm.Filings = filings;
                
            });

            this.dashboardData = alerts.AlertData.sort((a: DashboardFirm, b: DashboardFirm) => {
                return new Date(b.FilingCollection[0].Date).getTime() - new Date(a.FilingCollection[0].Date).getTime() ||
                    a.Name.localeCompare(b.Name);
            });
            this.loadingSpinner.end();
            this.firm = this.dashboardData.length > 0 ? this.dashboardData[0] : undefined;
        }
    }

    private getGroupedAlerts(allAlerts: DashboardAlert[]): AlertGroup[] {
       
        return [
            {
                Sort: 1, Label: 'Critical Updates', CssClass: 'Critical', Severity: 3,
                FontAwesomeIcon: 'fa-exclamation-triangle', Alerts: allAlerts.filter((i) => i.Severity === 3).sort((a: DashboardAlert, b: DashboardAlert) => {
                   if ( a.ShortDescription.startsWith("DRP")) return 1;
                   else if (b.ShortDescription.startsWith("DRP")) return -1
                   else return a.ShortDescription.localeCompare(b.ShortDescription, undefined, {
                        numeric: true,
                        sensitivity: 'base',

                      }) 

                })
            },
            {
                Sort: 2, Label: 'Important Updates', CssClass: 'Medium', Severity: 2,
                FontAwesomeIcon: 'fa-exclamation-circle', Alerts: allAlerts.filter((i) => i.Severity === 2).sort((a: DashboardAlert, b: DashboardAlert) => {
                                     
                    if ( a.ShortDescription.startsWith("DRP")) return 1;
                    else if (b.ShortDescription.startsWith("DRP")) return -1
                    else return a.ShortDescription.localeCompare(b.ShortDescription, undefined, {
                         numeric: true,
                         sensitivity: 'base',
 
                       }) 
                    
                })
            },
            {
                Sort: 3, Label: 'Notable Updates', CssClass: 'Low', Severity: 1,
                FontAwesomeIcon: 'fa-check-circle', Alerts: allAlerts.filter((i) => i.Severity === 1).sort((a: DashboardAlert, b: DashboardAlert) => {
                    if ( a.ShortDescription.startsWith("DRP")) return 1;
                    else if (b.ShortDescription.startsWith("DRP")) return -1
                    else return a.ShortDescription.localeCompare(b.ShortDescription, undefined, {
                         numeric: true,
                         sensitivity: 'base',
 
                       }) 

                })
            }
        ];
    }

  public onFilingSelected(ev: any) {
    const value = ev.target.value;
 }
 public getFirmByFilingDate(crdNumber: string, filingDate: Date): void {
    
    this.dataStore.selectedFilingsDate = filingDate;
    const filingcolection = this.dataStore.dashbordAlertData ? this.dataStore.dashbordAlertData.AlertData[0].FilingCollection : undefined;
    if(filingcolection)
    {
     const filings = filingcolection.filter(f=>f.Date === filingDate.toString());
     if(filings && filings.length > 0){
         this.processSelectFilingAlerts(filings);
        } else {
            this.loadingSpinner.begin();
            this.dataStore.getFirmByFilingDate(crdNumber, filingDate).subscribe((filings: DashboardFiling[]) => {
                if(this.dataStore.dashbordAlertData != null){
                    this.dataStore.dashbordAlertData.AlertData[0].FilingCollection = this.dataStore.dashbordAlertData.AlertData[0].FilingCollection.concat(filings);
                 }
                this.processSelectFilingAlerts(filings); 
                this.loadingSpinner.end();
            });
        }
    } else {
            this.loadingSpinner.begin();
            this.dataStore.getFirmByFilingDate(crdNumber, filingDate).subscribe((filings: DashboardFiling[]) => {
            if(this.dataStore.dashbordAlertData != null){
                this.dataStore.dashbordAlertData.AlertData[0].FilingCollection = this.dataStore.dashbordAlertData.AlertData[0].FilingCollection.concat(filings);
             }
            this.processSelectFilingAlerts(filings);
            this.loadingSpinner.end();
        });
    }
}
    private processSelectFilingAlerts(Alerts: DashboardFiling[]) {
        const allAlerts = selectMany(Alerts, (f: DashboardFiling) => f.Alerts);
        allAlerts.sort((a: DashboardAlert, b: DashboardAlert) => {
            return a.Severity - b.Severity || a.Title.localeCompare(b.Title);
        });
        const groupedAlerts: AlertGroup[] = this.getGroupedAlerts(allAlerts);
        if (this.firm) {
            this.firm.DashboardAlerts = groupedAlerts;
        }
    }

  public ngOnChanges(changes: import ('@angular/core').SimpleChanges): void {
    this.isTextFiltered = this.isFiltered(this.searchText);
}
public toggleShowAll(event: any, value: boolean): void {
    this.showAll = value;
}
public toggleShowConfirm(event: any, value: boolean): void {
    this.showArchiveAllDialog = value;
}
public gotoAlertDetail(AlertId: string): void {
    if(this.firm) {
        localStorage.setItem('firmId', this.firm.Id);
    }
    this.router.navigate(['home/alert', AlertId]);
}

public gotoFirmADV(crdNumber: string, filingDate: Date): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/advform', crdNumber, filingDate ]));
    window.open(url, '_blank');
}

public gotoFirmSummary(crdNumber: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/home/reports/firm', crdNumber]));
    window.open(url, '_blank');
}
public goBack(): void {
    this.location.back();
}
public toggleCollapsed(event: any, alertGroup: AlertGroup): void {
    const parentElement: HTMLElement = event.currentTarget.parentElement;
    this.alertCaretStatus.crdNumber = this.crdNumber;
    this.alertCaretStatus.severity = alertGroup.Severity;
    if (parentElement) {
        if (parentElement.classList.contains('collapsed')) {
            parentElement.classList.remove('collapsed');
            this.alertCaretStatus.isCollapsed = false; 
        } else {
            parentElement.classList.add('collapsed');
            this.alertCaretStatus.isCollapsed = true;
        }
        this.dataStore.setAlertCaretStatus(this.alertCaretStatus);
    }
}




public confirmArchive(FirmId: string): void {
    this.loadingSpinner.begin();
    this.dataStore.archiveAlerts(FirmId).subscribe(() => {
        location.reload();
    });
}

public getAlertCount(alerts: DashboardAlert[]): number {
    if (this.showAll) {
        return alerts.length;
    }
    return alerts.filter((a) => a.Acknowledged === false).length;
}

public getVisibleAlerts(alerts: DashboardAlert[]): DashboardAlert[] {
    if (this.showAll) {
        return alerts;
    }
    return alerts.filter((a) => a.Acknowledged === false);
}

public hasArchived(): boolean {
    if (this.firm) {
        return this.firm.DashboardAlerts.some((ag) => ag.Alerts.some((a) => a.Acknowledged === true));
    }
    return false;
}

private isFiltered(searchText: string): boolean {
    searchText = searchText.toLocaleLowerCase();
    if (searchText === '') { return true; }

    if (this.firm) {

        // Fund Manager
        if (this.firm.Name.toLowerCase().indexOf(searchText) > -1) { return true; }

        // Assigned Users
        const userMatch = this.firm.AssignedUsers.filter((user) => user.DisplayName.toLowerCase().indexOf(searchText) > -1);
        if (userMatch.length > 0) { return true; }

        // Alert Title & Description
        let alertData: DashboardAlert[] = [];
        this.firm.DashboardAlerts.forEach((group) => {
            alertData = alertData.concat(group.Alerts);
        });
        const titleMatch = alertData.filter((alert) => alert.Title.toLowerCase().indexOf(searchText) > -1);
        const descriptionMatch = alertData.filter((alert) => alert.ShortDescription.toLowerCase().indexOf(searchText) > -1);
        if (titleMatch.length > 0 || descriptionMatch.length > 0) { return true; }

        // Fund Name
        const fundNameMatch = alertData.filter((alert) => alert.FundName && alert.FundName.toLowerCase().indexOf(searchText) > -1)
        if (fundNameMatch.length > 0) { return true; }

        return false;
    }
    return false;
}

public fundNameSearch(fundName: string) {
    if(!fundName || this.searchText === '') return;
    var containsSearchText = fundName.toLowerCase().indexOf(this.searchText) > -1
    let styles = {'background-color': containsSearchText ? '#fcf8e3' : '#f9f9f9'};
    return styles;
}

public showExcelExportFlyOut(): void {
    const modalRef = this.excelExportModal.open(DashboardExcelExportComponent, { windowClass: 'flyout-right', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.dashboardData = this.dashboardData;
    modalRef.componentInstance.selectAllFirms = false;
    modalRef.componentInstance.showSelectedFirm = true;
}

// public getFirmByFilingDate(crdNumber: string, filingDate: Date): void {
//     this.loadingSpinner.begin();
//     this.dataStore.selectedFilingsDate = filingDate;
//     if(this.dataStore.dashbordAlertData != null)
//     {
//      const filings = this.dataStore.dashbordAlertData.AlertData[0].FilingCollection.filter(f=>f.Date === filingDate.toString());
//      if(filings && filings.length > 0){
//          this.dataStore.dashbordAlertData.AlertData[0].FilingCollection = this.dataStore.dashbordAlertData.AlertData[0].FilingCollection.concat(Alerts);
//      }
//     }

//     this.dataStore.getFirmByFilingDate(crdNumber, filingDate).subscribe((Alerts: DashboardFiling[]) => {
        
//        if(this.dataStore.dashbordAlertData!=null)
//        {
//         const indx= this.dataStore.dashbordAlertData.AlertData[0].FilingCollection.findIndex(f=>f.Date === filingDate.toString());
//         if(indx === -1){
//             this.dataStore.dashbordAlertData.AlertData[0].FilingCollection = this.dataStore.dashbordAlertData.AlertData[0].FilingCollection.concat(Alerts);
//         }
//        }
//         const allAlerts = selectMany(Alerts, (f: DashboardFiling) => f.Alerts);
//         allAlerts.sort((a: DashboardAlert, b: DashboardAlert) => {
//                 return a.Severity - b.Severity || a.Title.localeCompare(b.Title);
//             });
//         const groupedAlerts: AlertGroup[] = this.getGroupedAlerts(allAlerts);
//         if (this.firm) {
//             this.firm.DashboardAlerts = groupedAlerts;
//         }
//         this.loadingSpinner.end();
//     });
// }

ngOnDestroy() {
    // Emit something to stop all Observables
   this.subscription.unsubscribe();
  }
}
