import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { DataStore } from 'src/app/_form-adv/data/data.store';
import { FundSummaryResult } from 'src/app/_api/entities/reports';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingSpinner } from 'src/app/_concurrency/spinner/loading-spinner';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { DocumentLoadingModalComponent, ModalType } from 'src/app/advform/document-loading-modal.component';
import { PageMargin } from '@progress/kendo-drawing/pdf';

@Component({ templateUrl: 'reports-fund-summary.component.html', styleUrls: ['../../../assets/css/reports.scss'] })
export class ReportsFundSummaryComponent extends SubscriberEntity implements OnInit {

    public fundSummary!: FundSummaryResult;

    @ViewChild('pdf') public pdf!: PDFExportComponent;
    @ViewChild('pdfDataLink') public pdfDataLink!: ElementRef;
    @ViewChild('actionsContainer') public actionsContainer!: ElementRef;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private dataStore: DataStore,
        private modalService: NgbModal,
        public loadingSpinner: LoadingSpinner) { super(); }

    public ngOnInit(): void {

        this.loadingSpinner.begin();
        const crdNumber = this.route.snapshot.params.crdNumber;
        const secNumber = this.route.snapshot.params.secNumber;

        this.dataStore.getFundSummary(crdNumber, secNumber).subscribe((fundSummary: FundSummaryResult) => {
            this.fundSummary = fundSummary;
            this.loadingSpinner.end();
        });
    }

    public navigateToFirmSummary(crdNumber: string) {
        this.router.navigate(['/home/reports/firm', crdNumber]);
    }

    public navigateToFundSummary(crdNumber: string, secNumber: string, filingDate: Date) {
        this.loadingSpinner.begin();
        this.dataStore.getFundSummaryByFilingDate(crdNumber, secNumber, filingDate).subscribe((fundSummary: FundSummaryResult) => {
            this.fundSummary = fundSummary;
            this.loadingSpinner.end();
        });
    }

    public get pdfMargin(): PageMargin {
        const margins: PageMargin = {
          left: "0.5cm",
          right: "0.5cm",
          top: "2cm",
          bottom: "1cm"
        }
        return margins;
    }

    public savePdf(): void {
        if (this.pdf != null) {
          this.actionsContainer.nativeElement.className = 'col-md-6 actions-container d-none';
          const modalRef = this.modalService.open(DocumentLoadingModalComponent, { windowClass: 'pdf-loading-modal-container'});
          modalRef.componentInstance.modalType = ModalType.PDF;
    
          this.pdf.export().then((group: Group) => {
            exportPDF(group).then((dataUri: string) => {
              this.pdfDataLink.nativeElement.href = dataUri;
              this.pdfDataLink.nativeElement.download = `fundsummary-${this.fundSummary.SecNumber}`;
              this.pdfDataLink.nativeElement.click();
              this.actionsContainer.nativeElement.className = 'col-md-6 actions-container';
              this.modalService.dismissAll();
            });
          });
        }
    }
}