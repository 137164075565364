﻿import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { LoadingSpinner } from './_concurrency/spinner/loading-spinner';
import { ApiServices } from './_config/api-services';

// Modules
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConcurrencyModule, ModalComponent } from '@concurrency/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModules } from './_config/common-modules';
import {AdvformModule} from './advform/advform.module';
import { HomeModule } from './home/home.module';

// App Component and Configs
import { NgbDateParserFactory } from '@concurrency/angular';
import { AADSettings, B2CSettings } from '@concurrency/authentication';
import { LoggerModule } from 'ngx-logger';
import { aadSettings, b2cSettings } from './_config/authentication.configuration';
import { routeConfig } from './_config/route.configuration';
import { AppComponent } from './app.component';
import { StaticContentModule } from './static-content/static-content.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        RouterModule.forRoot(routeConfig),
        LoggerModule.forRoot({ level: environment.loggerLevel }),
        ConcurrencyModule.forRoot(environment),
        NgbModule.forRoot(),
        HomeModule,
        AdvformModule,
        ...CommonModules,
        StaticContentModule
    ],
    providers: [
        ...ApiServices,
        LoadingSpinner,
        {
            provide: NgbDateParserFormatter,
            useFactory: NgbDateParserFactory
        },
        {
            provide: AADSettings,
            useValue: aadSettings
        }, {
            provide: B2CSettings,
            useValue: b2cSettings
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
