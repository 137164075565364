import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { AdminView } from '../../_api/entities/admin';
import { AlertDetail, DashboardAlertComment } from '../../_api/entities/alert-data';
import { UserProfile } from '../../_api/entities/user-profile';
import { LoadingSpinner } from '../../_concurrency/spinner/loading-spinner';
import { DataStore } from '../../_form-adv/data/data.store';
import { UserStore } from '../../_form-adv/user/user.store';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({ templateUrl: 'alert.component.html', styleUrls: ['../../../assets/css/alert.scss'] })
export class AlertComponent extends SubscriberEntity implements OnInit {

    public alertDetail!: AlertDetail;
    public currentUser!: UserProfile;
    public clientSettings!: AdminView;
    public enableAcknowledgement = false;
    public static isalertModified=false;
    public comment = '';
    public requireComment = false;
    public isValid = false;

    constructor(
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        private userStore: UserStore,
        private dataStore: DataStore,
        public loadingSpinner: LoadingSpinner
    ) { super(); }

    public ngOnInit(): void {
        const crdNumber = this.route.snapshot.params.crdNumber;
        const filingDate = this.route.snapshot.params.filingDate;
        const alertId = this.route.snapshot.params.alertId;
        var isEmailAlert = crdNumber!=null&&filingDate!=null&&alertId!=null;
        if (isEmailAlert) {
            this.dataStore.getFirmWorkspaceByFillingDate(crdNumber, filingDate).subscribe((data) => {
                this.loadingSpinner.begin();
                this.dataStore.dashbordAlertData = data;
            },
                () => { },
                () => {
                    if (this.dataStore.dashbordAlertData != null) {
                        this.loadingSpinner.end();
                        this.processAlertData(alertId);
                    }
                }
            );
        }
        else this.processAlertData(alertId);
    }

    private processAlertData(alertId: any) {
        if (alertId) {
            this.loadingSpinner.begin();
            this.dataStore.getAlertDetail(alertId);
            this.userStore.profile.subscribe((data) => {
                if (data)
                    this.currentUser = data;
            });
            this.dataStore.alertdetail.pipe(filter(data => data != null && data.Alert.Id == alertId)).subscribe((data) => {
                const alertDetail = data;
                if (this.currentUser != null && alertDetail != null && alertDetail.AlertSettings != null) {
                    this.alertDetail = alertDetail;
                    const alertCriticality = alertDetail.Alert.Severity;
                    const clientSetting = alertDetail.AlertSettings.find((s) => s.Id === alertCriticality);
                    if (clientSetting) {
                        this.enableAcknowledgement = clientSetting.RequireAcknowledge;
                        this.requireComment = clientSetting.RequireComment;
                    }
                    this.canSubmit(this.comment);
                    this.loadingSpinner.end();
                }
            });
        }
    }

    public canSubmit(newValue: string): void {
        if (this.requireComment) {
            if ((!newValue || newValue === '')) {
                this.isValid = false;
                return;
            }
        }
        this.isValid = true;
    }

    public acknowledge(): void {
        this.loadingSpinner.begin();
        const comment = {} as DashboardAlertComment;
        comment.Author = `${this.currentUser.FirstName} ${this.currentUser.LastName}`;
        comment.Comment = this.comment;
        comment.DateTime = new Date();
        comment.IsAcknowledgement = true;
        this.alertDetail.Alert.Comments.push(comment);
        this.dataStore.acknowledgeAlert(this.alertDetail, this.comment).subscribe(() => {
            this.loadingSpinner.end();
            this.comment = '';
            this.alertDetail.Alert.Acknowledged = true;
            this.alertDetail.Alert.Comments = this.alertDetail.Alert.Comments.sort((a, b) => {
                return new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime();
            });
            this.dataStore.isAlertAcknowledged = true;
        });
    }

    public addComment(): void {
        this.loadingSpinner.begin();
        const comment = {} as DashboardAlertComment;
        comment.Author = `${this.currentUser.FirstName} ${this.currentUser.LastName}`;
        comment.Comment = this.comment;
        comment.DateTime = new Date();
        comment.IsAcknowledgement = false;
        this.alertDetail.Alert.Comments.push(comment);
        this.dataStore.isAlertModifed=true;
        this.dataStore.modifiedAlerts.push(this.route.snapshot.params.alertId);
        this.dataStore.addComment(this.alertDetail, this.comment).subscribe(() => {
            this.loadingSpinner.end();
            this.comment = '';
            this.alertDetail.Alert.Comments = this.alertDetail.Alert.Comments.sort((a, b) => {
                return new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime();
            });
        });
    }

    public goToDashboard(): void {
       this.router.navigate(['home/dashboard']);
    }

    public goBack()
    {
        this.location.back();
    }

    public gotoFirmADV(crdNumber: string, filingDate: Date): void {
        const url = this.router.serializeUrl(this.router.createUrlTree(['/advform', crdNumber, filingDate ]));
        window.open(url, '_blank');
    }
}
