import { Component, Input, OnInit } from '@angular/core';
import { ADVJurisdictions } from '../../_api/entities/states';

@Component({
  selector: 'advform-item1b',
  templateUrl: './advform-item1b.component.html',
  styleUrls: ['../../advform/advform.component.scss']
  })
export class AdvformItem1BComponent implements OnInit {

  @Input('sec1BItem') public sec1BItem!: any;

  public jurisdictions: ADVJurisdictions;
  public otherString: string = "";

  constructor() {
    this.jurisdictions = {
      States: {
        AL: 'N',
        AK: 'N',
        AZ: 'N',
        AR: 'N',
        CA: 'N',
        CO: 'N',
        CT: 'N',
        DE: 'N',
        DC: 'N',
        FL: 'N',
        GA: 'N',
        GU: 'N',
        HI: 'N',
        ID: 'N',
        IL: 'N',
        IN: 'N',
        IA: 'N',
        KS: 'N',
        KY: 'N',
        LA: 'N',
        ME: 'N',
        MD: 'N',
        MA: 'N',
        MI: 'N',
        MN: 'N',
        MS: 'N',
        MO: 'N',
        MT: 'N',
        NE: 'N',
        NV: 'N',
        NH: 'N',
        NJ: 'N',
        NM: 'N',
        NY: 'N',
        NC: 'N',
        ND: 'N',
        OH: 'N',
        OK: 'N',
        OR: 'N',
        PA: 'N',
        PR: 'N',
        RI: 'N',
        SC: 'N',
        SD: 'N',
        TN: 'N',
        TX: 'N',
        UT: 'N',
        VT: 'N',
        VI: 'N',
        VA: 'N',
        WA: 'N',
        WV: 'N',
        WI: 'N',
        WY: 'N',
      },
      Other: 'N'
     };
  }

  private setJurisdiction(name: string): void {
      let ev = `this.jurisdictions.States.${name}='Y';`
      if(name.includes('Other')) {
        ev = `this.jurisdictions.Other='Y';`
        this.otherString = name.split(':')[1].trim();
      }
      eval(ev);
  }

  public ngOnInit(): void {
    if (this.sec1BItem && this.sec1BItem != null) {
        const itemJurisdictions = this.sec1BItem.Jurisdictions.split(';');
        for(let i = 0; i < itemJurisdictions.length; i++) {
            this.setJurisdiction(itemJurisdictions[i].trim());
        }
    }
  }

}
