import { Component, Input, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-check',
    templateUrl: 'advform-check.component.html',
    styleUrls: ['./advform-check.component.scss']
  })
  export class AdvformCheckComponent extends SubscriberEntity implements OnInit {

    @Input('formObj') public formObj: any;
    @Input('referenceString') public referenceString = '';

    public isChecked = false;

    constructor(
      private advFormDetailService: AdvFormDetailService
    ) {
        super();
    }

    public ngOnInit(): void {
      const value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);

      if (value === 'Y') {
        this.isChecked = true;
      }
    }

  }
