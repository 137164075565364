import { Component, Input, OnInit } from '@angular/core';
import { AdvFormDetailService } from '../../../advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-check-commatext',
    templateUrl: 'advform-check-commatext.component.html',
    styleUrls: ['./advform-check.component.scss']
})
export class AdvFormCheckCommaTextComponent implements OnInit {

    @Input('matchValue') public matchValue = '';
    @Input('formObj') public formObj: any;
    @Input('referenceString') public referenceString = '';

    public isChecked = false;

    constructor(private advFormDetailService: AdvFormDetailService) {}

    public ngOnInit(): void {
        const value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);

        if (value) {
            this.isChecked = value.indexOf(this.matchValue) > -1;
        }
    }

}
