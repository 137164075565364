import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {AdvformDetailData} from '../../_api/entities/advform-data';

@Component({
  selector: 'advform-signaturepage',
  templateUrl: './advform-signaturepage.component.html',
  styleUrls: ['../../advform/advform.component.scss']
  })
export class AdvformSignaturePageComponent implements OnInit {
  private collapseToggle: boolean = false;

  @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
  @Input('detail') public detail: AdvformDetailData | undefined;

  constructor() { }

  public ngOnInit(): void {
  }

  public toggleCollapseEvent() {
    this.collapseToggle = !this.collapseToggle;
    this.collapseEvent.emit(this.collapseToggle);
  }

}
