import { Component, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { AdminView } from '../../_api/entities/admin';
import { DataStore } from '../../_form-adv/data/data.store';
import { CreateUserFormModel } from './models/create-user-form-model';
import { LoadingSpinner } from 'src/app/_concurrency/spinner/loading-spinner';
import { Router } from '@angular/router';
import { UserProfile } from 'src/app/_api/entities/user-profile';

@Component({
    templateUrl: 'create-user.component.html',
    styleUrls: ['../../../assets/css/create-user.scss']
})
export class CreateUserComponent extends SubscriberEntity implements OnInit {

    public user = {} as CreateUserFormModel;
    public roles = new Array<string>();
    public directors!: UserProfile[];

    constructor(
        private dataStore: DataStore,
        private loadingSpinner: LoadingSpinner,
        private router: Router
    ) { super(); }

    public ngOnInit(): void {
        this.loadingSpinner.begin();
        this.dataStore.clientSettings.whileDefined((data: AdminView) => {
            this.roles = data.AssignableRoles;
            this.directors = data.Users
                .filter(u => {
                    return u.Role === 'Director';
                })
                .sort((a, b) =>
                {
                    if(!a.FirstName) return 1;
                    if(!b.FirstName) return -1;
                    return a.FirstName.localeCompare(b.FirstName);
                });
            this.loadingSpinner.end();
        });
    }

    public createUser():void {
        this.loadingSpinner.begin();
        this.dataStore.createUser(this.user).subscribe(() => {
            this.loadingSpinner.end();
            this.navigateToAdmin();
        });
    }

    public navigateToAdmin(): void {
        this.router.navigate(['/home/admin']);
    }
}
