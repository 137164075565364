import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UserProfile } from '../_api/entities/user-profile';
import { UserStore } from './user/user.store';

@Injectable()
export class CanAccessGuard implements CanActivate {
    constructor(private userStore: UserStore) { }

    // TODO: Why does this only work with a promise and not an Observable?
    // see: https://stackoverflow.com/questions/41131476/emptyerror-no-elements-in-sequence
    public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        // tslint:disable-next-line: no-string-literal
        const permissions = route.data['permissions'] as string[];
        return new Promise((resolve, reject) => {
            this.userStore.profile.onceDefined((profile: UserProfile) => {
                resolve(permissions.findIndex((p) => p === profile.Role) > -1);
            });
        });
    }
}