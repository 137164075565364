import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

type ExitState = 'save'|'dismiss';

// EventManager is for managing events, not data.
// To this end, all events must be of type Subject - not BehaviorSubject.
@Injectable()
export class EventManager {
    private _recalculate = new Subject();
    private _resummarize = new Subject<number>();
    private _allowExport = new Subject<boolean>();

    public recalculate = this._recalculate.asObservable();
    public resummarize = this._resummarize.asObservable();
    public allowExport = this._allowExport.asObservable();

    public triggerRecalculate(): void {
        this._recalculate.next();
    }

    public triggerResummarize(pageTop: number): void {
        this._resummarize.next(pageTop);
    }

    public triggerAllowExport(allow: boolean): void {
        this._allowExport.next(allow);
    }
}
