import { Component, Input, OnInit } from '@angular/core';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-radio-label',
    templateUrl: 'advform-radio-label.component.html',
    styleUrls: ['./advform-radio-label.component.scss']
})
export class AdvformCombinationRadioLabelComponent implements OnInit {

    @Input('radioArray') public radioArray = new Array<string>();
    @Input('referenceString') public referenceString = '';
    @Input('labelNote') public labelNote = '';
    @Input('formObj') public formObj: any;
    @Input('radioName') public radioName = '';

    public radioValue = '';
    public labelValue = '';

    constructor(private advFormDetailService: AdvFormDetailService) { }

    public ngOnInit(): void {
        const value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);

        if (value) {
            const index = value.indexOf(',');

            const valueSplit = [value.slice(0, index), value.slice(index + 1)];

            this.radioValue = valueSplit[0];
            this.labelValue = valueSplit[1];
        }
    }
}
