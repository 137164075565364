import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import {AdvformDetailData} from '../../app/_api/entities/advform-data';

@Component({
  selector: 'advform-detail',
  templateUrl: './advform-detail.component.html',
  styleUrls: ['./advform-detail.component.scss']
})
export class AdvformDetailComponent implements OnInit, AfterViewInit {

  @Output('initialized') public initialized: EventEmitter<boolean> = new EventEmitter();
  @Output('recalculateContainers') public recalculateContainers: EventEmitter<boolean> = new EventEmitter();
  @Input('detail') public detail: AdvformDetailData | undefined;

  constructor() { }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.initialized.emit(true);
  }

  public collapseEventHandler($event: boolean): void {
    this.recalculateContainers.emit($event);
  }

}
