import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AdvformDetailData } from 'src/app/_api/entities/advform-data';
import { AdvFormDetailService } from '../services/advform-detail-service.component';

@Component({
    selector: 'advform-item4',
    templateUrl: './advform-item4.component.html',
    styleUrls: ['../../advform/advform.component.scss']
})
export class AdvformItem4Component implements OnInit {
    private collapseToggle: boolean = false;

    @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
    @Input('detail') public detail: AdvformDetailData | undefined;

    constructor(
        public advFormDetailService: AdvFormDetailService
    ) { }

    public ngOnInit(): void {
    }

    public toggleCollapseEvent() {
        this.collapseToggle = !this.collapseToggle;
        this.collapseEvent.emit(this.collapseToggle);
    }

}
