import { Injectable } from "@angular/core";
import {AdvformDetailData} from "../entities/advform-data";

@Injectable()
export class ADVFormDataService {

    public getFormData(firmId:string):AdvformDetailData {
      var sourcedata:any = 
      {
        "id": "168211",
        "UpdateDate": "9/5/2019",
        "7B": [],
        "7B2": [],
        "DRPsCIV": [],
        "DRPsREG": [],
        "ScheduleA": {
          "SA_3": "N",
          "SA_7": [
            {
              "FULL LEGAL NAME": "JACKSON, LYMAN, HOOD",
              "DE/FE/I": " I",
              "Title or Status": " MEMBER AND CHIEF COMPLIANCE OFFICER",
              "Date Title or Status Acquired": " 07/2013",
              "Ownership Code": " D",
              "Control Person": " Y",
              "PR": " N",
              "CRD No.": " 1263219"
            },
            {
              "FULL LEGAL NAME": "FINGERMAN, RICHARD, HOWARD",
              "DE/FE/I": " I",
              "Title or Status": " MEMBER",
              "Date Title or Status Acquired": " 07/2013",
              "Ownership Code": " D",
              "Control Person": " Y",
              "PR": " N",
              "CRD No.": " 1976526"
            }
          ]
        },
        "ScheduleD": {
          "SD_1_B": [],
          "SD_1_F": [],
          "SD_1_I": [ { "Website": "HTTP://WWW.PLANWITHFPS.COM" } ],
          "SD_1_L": [],
          "SD_1_M": [],
          "SD_2_A_8": [],
          "SD_2_A_9_1": "",
          "SD_2_A_9_2": "",
          "SD_2_A_10_1": "",
          "SD_2_A_10_2": "",
          "SD_2_A_10_3": "",
          "SD_2_A_12_Number": "",
          "SD_2_A_12_Date": "",
          "SD_4": "",
          "SD_5_G_3": [],
          "SD_5_I_2": [],
          "SD_5_K_1_A_i": "%, %",
          "SD_5_K_1_A_ii": "%, %",
          "SD_5_K_1_A_iii": "%, %",
          "SD_5_K_1_A_iv": "%, %",
          "SD_5_K_1_A_v": "%, %",
          "SD_5_K_1_A_vi": "%, %",
          "SD_5_K_1_A_vii": "%, %",
          "SD_5_K_1_A_viii": "%, %",
          "SD_5_K_1_A_ix": "%, %",
          "SD_5_K_1_A_x": "%, %",
          "SD_5_K_1_A_xi": "%, %",
          "SD_5_K_1_A_xii": "%, %",
          "SD_5_K_1_B_i": "35 %",
          "SD_5_K_1_B_ii": "0 %",
          "SD_5_K_1_B_iii": "1 %",
          "SD_5_K_1_B_iv": "10 %",
          "SD_5_K_1_B_v": "0 %",
          "SD_5_K_1_B_vi": "1 %",
          "SD_5_K_1_B_vii": "0 %",
          "SD_5_K_1_B_viii": "0 %",
          "SD_5_K_1_B_ix": "43 %",
          "SD_5_K_1_B_x": "0 %",
          "SD_5_K_1_B_xi": "10 %",
          "SD_5_K_1_B_xii": "0 %",
          "SD_5_K_2": "",
          "SD_5_K_3": [
            {
              "A": "TD AMERITRADE CLEARING, INC.",
              "B": "TD AMERITRADE CLEARING, INC.",
              "C": "OMAHA, Nebraska, United States",
              "D": "N",
              "E": "8 - 16335",
              "F": "",
              "G": "$ 99,736,053"
            }
          ],
          "SD_6_A": "",
          "SD_6_B_2": "",
          "SD_6_B_3": "",
          "SD_7_A": []
        },
        "ScheduleB": {
          "SB_7": [
            {
              "FULL LEGAL NAME": "PAUL A. PAGNATO JR, TRUST",
              "DE/FE/I": " DE",
              "Entity in Which Interest is Owned": " PAGNATOKARP, LLC",
              "Status": " MEMBER",
              "Date Status Acquired": " 12/2015",
              "Ownership Code": " E",
              "Control Person": " Y",
              "PR": " N",
              "CRD No.": ""
            },
            {
              "FULL LEGAL NAME": "PAGNATO, PAUL, ATTILIO JR.",
              "DE/FE/I": " I",
              "Entity in Which Interest is Owned": " PAUL A. PAGNATO JR, TRUST",
              "Status": " TRUSTEE",
              "Date Status Acquired": " 02/2004",
              "Ownership Code": " F",
              "Control Person": " Y",
              "PR": " N",
              "CRD No.": " 2224626"
            },
            {
              "FULL LEGAL NAME": "KARP, DAVID, WILLIAM",
              "DE/FE/I": " I",
              "Entity in Which Interest is Owned": " DAVID KARP 2014 TRUST",
              "Status": " TRUSTEE",
              "Date Status Acquired": " 12/2015",
              "Ownership Code": " F",
              "Control Person": " Y",
              "PR": " N",
              "CRD No.": " 2587445"
            },
            {
              "FULL LEGAL NAME": "PAGNATOKARP, LLC",
              "DE/FE/I": " DE",
              "Entity in Which Interest is Owned": " PAGNATOKARP EQUITY, LLC",
              "Status": " MANAGING MEMBER",
              "Date Status Acquired": " 12/2015",
              "Ownership Code": " E",
              "Control Person": " Y",
              "PR": " N",
              "CRD No.": ""
            },
            {
              "FULL LEGAL NAME": "DAVID KARP 2014 TRUST",
              "DE/FE/I": " DE",
              "Entity in Which Interest is Owned": " PAGNATOKARP LLC",
              "Status": " MEMBER",
              "Date Status Acquired": " 12/2015",
              "Ownership Code": " C",
              "Control Person": " Y",
              "PR": " N",
              "CRD No.": ""
            }
          ]
              },
        "ScheduleR": [],
        "Signature": {
          "SIG_DS": "LYMAN JACKSON",
          "SIG_DD": "09/04/2019",
          "SIG_DN": "LYMAN JACKSON",
          "SIG_DT": "MANAGING PARTNER AND CO-FOUNDER",
          "SIG_NS": "",
          "SIG_ND": "",
          "SIG_NN": "",
          "SIG_NT": ""
        },
        "Info": {
          "SECRgnCD": "BRO",
          "FirmCrdNb": "168211",
          "SECNb": "801-113221",
          "BusNm": "FINANCIAL PLANNING SOLUTIONS, LLC",
          "LegalNm": "FINANCIAL PLANNING SOLUTIONS, LLC",
          "UmbrRgstn": "N"
        },
        "MainAddr": {
          "Strt1": "120 WELLS AVENUE",
          "City": "NEWTON",
          "State": "MA",
          "Cntry": "United States",
          "PostlCd": "02459",
          "PhNb": "617-630-4978"
        },
        "MailingAddr": null,
        "Rgstn": {
          "FirmType": "Registered",
          "St": "APPROVED",
          "Dt": "2018-05-14"
        },
        "NoticeFiled": {
          "States": [{
            "RgltrCd": "MA",
            "St": "FILED",
            "Dt": "2018-05-14"
          }]
        },
        "Filing": {
          "Dt": "2019-09-04",
          "FormVrsn": "10/2017"
        },
        "FormInfo": {
          "Part1A": {
            "Item1": {
              "Q1F5": "0",
              "Q1I": "Y",
              "Q1M": "N",
              "Q1N": "N",
              "Q1O": "N",
              "WebAddrs": { "WebAddr": "HTTP://WWW.PLANWITHFPS.COM" }
            },
            "Item2A": {
              "Q2A1": "Y",
              "Q2A2": "N",
              "Q2A4": "N",
              "Q2A5": "N",
              "Q2A6": "N",
              "Q2A7": "N",
              "Q2A8": "N",
              "Q2A9": "N",
              "Q2A10": "N",
              "Q2A11": "N",
              "Q2A12": "N",
              "Q2A13": "N"
            },
            "Item2B": null,
            "Item3A": { "OrgFormNm": "Limited Liability Company" },
            "Item3B": { "Q3B": "DECEMBER" },
            "Item3C": {
              "StateCD": "MA",
              "CntryNm": "United States"
            },
            "Item5A": { "TtlEmp": "5" },
            "Item5B": {
              "Q5B1": "3",
              "Q5B2": "0",
              "Q5B3": "3",
              "Q5B4": "0",
              "Q5B5": "1",
              "Q5B6": "0"
            },
            "Item5C": {
              "Q5C1": "0",
              "Q5C2": "1"
            },
            "Item5D": {
              "Q5DA1": "101",
              "Q5DA3": "16249403",
              "Q5DB1": "62",
              "Q5DB3": "102673428"
            },
            "Item5E": {
              "Q5E1": "Y",
              "Q5E2": "N",
              "Q5E3": "N",
              "Q5E4": "Y",
              "Q5E5": "N",
              "Q5E6": "N",
              "Q5E7": "N"
            },
            "Item5F": {
              "Q5F1": "Y",
              "Q5F2A": "105042263",
              "Q5F2B": "13880568",
              "Q5F2C": "118922831",
              "Q5F2D": "583",
              "Q5F2E": "212",
              "Q5F2F": "795",
              "Q5F3": "1325500"
            },
            "Item5G": {
              "Q5G1": "Y",
              "Q5G2": "Y",
              "Q5G3": "N",
              "Q5G4": "N",
              "Q5G5": "N",
              "Q5G6": "Y",
              "Q5G7": "N",
              "Q5G8": "N",
              "Q5G9": "N",
              "Q5G10": "N",
              "Q5G11": "N",
              "Q5G12": "N"
            },
            "Item5H": { "Q5H": "26-50" },
            "Item5I": { "Q5I1": "N" },
            "Item5J": {
              "Q5J1": "N",
              "Q5J2": "N"
            },
            "Item6A": null,
            "Item6B": {
              "Q6B1": "N",
              "Q6B3": "N"
            },
            "Item7A": null,
            "Item7B": { "Q7B": "N" },
            "Item8A": {
              "Q8A1": "N",
              "Q8A2": "Y",
              "Q8A3": "N"
            },
            "Item8B": {
              "Q8B1": "N",
              "Q8B2": "N",
              "Q8B3": "N"
            },
            "Item8C": {
              "Q8C1": "Y",
              "Q8C2": "Y",
              "Q8C3": "N",
              "Q8C4": "N"
            },
            "Item8D": null,
            "Item8E": { "Q8E": "Y" },
            "Item8F": { "Q8F": "N" },
            "Item8G": { "Q8G1": "N" },
            "Item8H": {
              "Q8H1": "N",
              "Q8H2": "N"
            },
            "Item8I": { "Q8I": "N" },
            "Item9A": {
              "Q9A1A": "N",
              "Q9A1B": "N"
            },
            "Item9B": {
              "Q9B1A": "N",
              "Q9B1B": "N"
            },
            "Item9C": {
              "Q9C1": "N",
              "Q9C2": "N",
              "Q9C3": "N",
              "Q9C4": "N"
            },
            "Item9D": {
              "Q9D1": "N",
              "Q9D2": "N"
            },
            "Item9E": null,
            "Item9F": null,
            "Item10A": { "Q10A": "N" },
            "Item11": { "Q11": "N" },
            "Item11A": {
              "Q11A1": "N",
              "Q11A2": "N"
            },
            "Item11B": {
              "Q11B1": "N",
              "Q11B2": "N"
            },
            "Item11C": {
              "Q11C1": "N",
              "Q11C2": "N",
              "Q11C3": "N",
              "Q11C4": "N",
              "Q11C5": "N"
            },
            "Item11D": {
              "Q11D1": "N",
              "Q11D2": "N",
              "Q11D3": "N",
              "Q11D4": "N",
              "Q11D5": "N"
            },
            "Item11E": {
              "Q11E1": "N",
              "Q11E2": "N",
              "Q11E3": "N",
              "Q11E4": "N"
            },
            "Item11F": { "Q11F": "N" },
            "Item11G": { "Q11G": "N" },
            "Item11H": {
              "Q11H1A": "N",
              "Q11H1B": "N",
              "Q11H1C": "N",
              "Q11H2": "N"
            }
          }
        }
      };

      //
      //  going to have to crowbar some data for the bindings to work
      //
      if(sourcedata.FormInfo.Part1A.Item6A == null)
      {
        sourcedata.FormInfo.Part1A.Item6A = {};
      }
      if(sourcedata.FormInfo.Part1A.Item7A == null)
      {
        sourcedata.FormInfo.Part1A.Item7A = {};
      }
      if(sourcedata.FormInfo.Part1A.Item8D == null)
      {
        sourcedata.FormInfo.Part1A.Item8D = {};
      }
      if(sourcedata.FormInfo.Part1A.Item9E == null)
      {
        sourcedata.FormInfo.Part1A.Item9E = {};
      }
      if(sourcedata.FormInfo.Part1A.Item9F == null)
      {
        sourcedata.FormInfo.Part1A.Item9F = {};
      }

          return sourcedata;
    }
   
}