import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DashboardFirm } from 'src/app/_api/entities/alert-data';

@Injectable()
export class DataService {

    public firm: DashboardFirm | undefined;
  private messageSource = new BehaviorSubject('default message');
  private firmData =  new BehaviorSubject<any>({} as any);
  public firmData$ = this.firmData.asObservable();
  public searchText = '';
  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  public changeFirmData(firm: DashboardFirm| undefined, searchText: string) {
    const current = this.firmData.getValue();
   // current.groupId = groupId;
   //
    current.firm = firm;
    current.searchText = searchText;
    this.firmData.next(current);
    console.log(this.firmData);
  }
}
