import { Component, Input, OnInit } from '@angular/core';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-label-radio-textarea',
    templateUrl: 'advform-label-radio-textarea.component.html',
    styleUrls: ['./advform-label-radio-textarea.component.scss']
})
export class AdvformCombinationRadioLabelTextareaComponent implements OnInit {

    @Input('referenceString') public referenceString = '';
    @Input('referenceString_DateType') public referenceString_datetype = '';
    @Input('referenceString_Explanation') public referenceString_explanation = '';
    @Input('labelNote') public labelNote = '';
    @Input('formObj') public formObj: any;
    @Input('radioName') public radioName = '';
    @Input('redcolor') public redcolor = 'N';


    public exactRadio = 'N';
    public explainationRadio = 'N';
    public textAreaValue = '';
    public labelValue = '';
    public fullWidth = false;
    public isredcolor = false;

    constructor(private advFormDetailService: AdvFormDetailService) { }

    public ngOnInit(): void {
        const value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);
        const value_type = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString_datetype);
        const value_explanation = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString_explanation);

        if (value) {
            const valueSplit = value.split(', ');

            this.labelValue = valueSplit[0];

            if (valueSplit[1]) {
                this.explainationRadio = 'Y';
                this.textAreaValue = value.slice(this.labelValue.length+1);
                this.textAreaValue.trim();
            }  else {
                this.exactRadio = 'Y';
            }

           

            if (this.textAreaValue && this.textAreaValue.length > 300) {
                this.fullWidth = true;
            }
            if(this.redcolor == 'Y'){
                this.isredcolor = true;
            }
        }

        if (value_type) {

             if(value_type && value_type.length > 0){
                this.exactRadio = 'Y';
            }
        }

        if (value_explanation) {

            if(value_explanation && value_explanation.length > 0){
                this.explainationRadio = 'Y';
                this.textAreaValue =  value_explanation;
                this.textAreaValue.trim();
            }

            if (this.textAreaValue && this.textAreaValue.length > 300) {
                this.fullWidth = true;
            }
            if(this.redcolor == 'Y'){
                this.isredcolor = true;
            }
        }
    }
}
