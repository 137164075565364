import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvformDetailData } from '../../_api/entities/advform-data';
import { AdvFormDetailService } from '../services/advform-detail-service.component';

@Component({
    selector: 'advform-scheduled',
    templateUrl: './advform-scheduled.component.html',
    styleUrls: ['../../advform/advform.component.scss']
})
export class AdvformScheduleDComponent implements OnInit {
    private collapseToggle = false;

    @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
    @Input('detail') public detail: AdvformDetailData | undefined;

    constructor(public advFormDetailService: AdvFormDetailService) { }

    public ngOnInit(): void {}

    public toggleCollapseEvent(): void {
        this.collapseToggle = !this.collapseToggle;
        this.collapseEvent.emit(this.collapseToggle);
    }
}
