import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {AdvformDetailData} from '../../_api/entities/advform-data';

@Component({
  selector: 'advform-item3',
  templateUrl: './advform-item3.component.html',
  styleUrls: ['../../advform/advform.component.scss']
  })
export class AdvformItem3Component implements OnInit {
  private collapseToggle: boolean = false;

  @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
  @Input('detail') public detail: AdvformDetailData | undefined;

  public selection = '';
  public otherSelection = '';

  constructor() { }

  public ngOnInit(): void {
    if (this.detail && this.detail.FormInfo.Part1A.Item3A) {
      this.selection = this.detail.FormInfo.Part1A.Item3A.OrgFormNm;
      switch (this.selection) {
          case 'Corporation':
          case 'Sole Proprietorship':
          case 'Limited Liability Partnership':
          case 'Partnership':
          case 'Limited Liability Company':
          case 'Limited Partnership':
            break;
          default:
            this.otherSelection = this.selection;  // case "Other"
            this.selection = 'Other';
            break;
      }
    }
  }

  public toggleCollapseEvent() {
    this.collapseToggle = !this.collapseToggle;
    this.collapseEvent.emit(this.collapseToggle);
  }

}
