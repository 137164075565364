import { Component, OnInit } from '@angular/core';
import { UserStore } from '../_form-adv/user/user.store';

@Component({ template: '' })
export class CustomerLoginComponent implements OnInit {
    constructor(
        private userStore: UserStore
    ) { }

    public ngOnInit(): void {
        this.userStore.loginUser();
    }
}
