import { Component, Input, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';

@Component({
    selector: 'advform-check-value',
    templateUrl: 'advform-check-value.component.html',
    styleUrls: ['./advform-check.component.scss']
})
export class AdvformCheckValueComponent extends SubscriberEntity implements OnInit {
    @Input('value') public value = '';

    public isChecked = false;

    constructor(
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.value === 'Y') {
            this.isChecked = true;
        }
    }

}
