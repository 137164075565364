import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';

export enum FieldType {
    String,
    Percentage,
    Currency,
    Date,
    Array
}

export const FieldTypes: { [key: number ]: string } = {};
FieldTypes[FieldType.String] = 'String';
FieldTypes[FieldType.Percentage] = 'Percentage';
FieldTypes[FieldType.Currency] = 'Currency';
FieldTypes[FieldType.Date] = 'Date';
FieldTypes[FieldType.Array] = 'Array';

@Pipe({
    name: 'fieldtype'
})
export class FieldTypeFormatPipe implements PipeTransform {

    transform(value: string | null, args: string): any {
        if (value && args) {
            if(value !== null && value !== '') {
                switch (args) {
                    case FieldTypes[FieldType.String]:
                        if(value.trim().toUpperCase() === 'N') {
                            value = 'No';
                        }
                        if(value.trim().toUpperCase() === 'Y') {
                            value = 'Yes';
                        }
                        break;
                    case FieldTypes[FieldType.Percentage]: 
                        value = `${value}%`;
                        break;
                    case FieldTypes[FieldType.Currency]: 
                        value = (new CurrencyPipe('en-US')).transform(value, 'USD', true, '1.0-0');
                        break;
                    case FieldTypes[FieldType.Date]: 
                        value = (new DatePipe('en-US')).transform(value, 'mediumDate');
                        break;
                    case FieldTypes[FieldType.Array]:
                        if(value !== null) {
                            try {
                                let parsedValue = JSON.parse(value.toString());
                                if(Array.isArray(parsedValue)) {
                                    let arrayValue = <Array<any>> parsedValue;
                                    if(arrayValue.length === 0) {
                                        value = '';
                                    } else {
                                        // Determine type of Array
                                        const firstElement = arrayValue[0];
                                        if(typeof firstElement === 'string' || typeof firstElement === 'number') {
                                            value = arrayValue.join(', ');
                                        } else if (typeof firstElement === 'object') {
                                            value = 'Too large to display, please view Firm Summary or complete Form ADV.'
                                        }
                                    }
                                }
                            } catch(err) {
                                // Unable to parse as a JSON array
                                return value;
                            }
                        } 
                        break;
                    default: { }
                }
            }
        }
        return value;
    }
}