import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AdvformDetailData } from 'src/app/_api/entities/advform-data';

@Component({
    selector: 'advform-item10',
    templateUrl: './advform-item10.component.html',
    styleUrls: ['../../advform/advform.component.scss']
})
export class AdvformItem10Component implements OnInit {
    private collapseToggle: boolean = false;

    @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
    @Input('detail') public detail: AdvformDetailData | undefined;

    constructor() { }

    public ngOnInit(): void {
    }

    public toggleCollapseEvent() {
        this.collapseToggle = !this.collapseToggle;
        this.collapseEvent.emit(this.collapseToggle);
    }

}
