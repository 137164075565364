import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriberEntity, ModalManager, NetworkManager, HttpError, ModalComponent } from '@concurrency/angular';
import { AdminView, Firm } from '../../_api/entities/admin';
import { UserProfile } from '../../_api/entities/user-profile';
import { DataStore } from '../../_form-adv/data/data.store';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingSpinner } from 'src/app/_concurrency/spinner/loading-spinner';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, merge } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from 'src/app/home/modals/confirmation/confirmation-dialog.service';


@Component({ templateUrl: 'user.component.html', styleUrls: ['../../../assets/css/user.scss'] })
export class UserComponent extends SubscriberEntity implements OnInit {

    public userDetail!: UserProfile;
    public directors!: UserProfile[];
    public ClientSettings!: AdminView;
    public copyToUser!: string;
    public copyToDirector!: string;
    public firmsCopied = false;
    public analystsCopied = false;

    @Input() public selectedFirm: any;
    public searching = false;
    public searchFailed = false;

    constructor(
        private route: ActivatedRoute,
        private dataStore: DataStore,
        private modalManager: ModalManager,
        private loadingSpinner: LoadingSpinner,
        private confirmationDialogService: ConfirmationDialogService
    ) { super(); }

    public ngOnInit(): void {

        this.loadingSpinner.begin();

        const userId = this.route.snapshot.params.userId;
        this.dataStore.selectUserDetail(userId);
        
        const request = combineLatest([this.dataStore.clientSettings, this.dataStore.userDetail]);
        request.subscribe((data: [AdminView | undefined, UserProfile | undefined]) => {
            const clientSettings = data[0];
            const userDetail = data[1];

            if(clientSettings != null && userDetail != null) {
                this.ClientSettings = clientSettings;
                this.ClientSettings.Users = this.ClientSettings.Users.sort((a, b) =>
                {
                    if(!a.FirstName) return 1;
                    if(!b.FirstName) return -1;
                    return a.FirstName.localeCompare(b.FirstName);
                });
                this.userDetail = userDetail;
                this.directors = this.ClientSettings.Users
                .filter(u => {
                    if(u.Role === 'Director' && u.Id !== this.userDetail.Id) {
                        if(this.userDetail.ReportsTo && u.Id == this.userDetail.ReportsTo.Id){
                            return false;
                        }
                        return true;
                    }
                    return false;
                })
                .sort((a, b) =>
                {
                    if(!a.FirstName) return 1;
                    if(!b.FirstName) return -1;
                    return a.FirstName.localeCompare(b.FirstName);
                });
                
                this.loadingSpinner.end();
            }
        });
    }

    public onSelectItem(event: NgbTypeaheadSelectItemEvent): void {
        const firm: Firm = event.item;
        this.selectedFirm = firm;
    }

    public resultFormatter(result: Firm): string {
        return result.FirmName;
    }

    public inputFormatter(result: Firm) {
        return result.FirmName;
    }

    public search = (text$: Observable<string>) =>
        text$
        .pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => (this.searching = true)),
            switchMap((term) =>
                this.dataStore.searchFirms(term)
                .pipe(
                    tap(() => {
                        this.searchFailed = false;
                    }),
                    catchError(() => {
                        this.searchFailed = true;
                        return new Observable<any[]>();
                    })
                )
            ),
            tap(() => {
                this.searching = false;
            })
        )
    

    public addFirm(): void {
        if (this.selectedFirm) {
            this.loadingSpinner.begin();
            this.dataStore.addFirm(this.userDetail.Id, this.selectedFirm.Id)
                .subscribe(() => {
                    this.selectedFirm = undefined;
                    this.loadingSpinner.end();
                }, (error: HttpErrorResponse) => {
                    this.loadingSpinner.end();
                    if (error.status === 409) {
                        this.modalManager.error('Cannot assign new fund manager.  ' +
                        'Your subscription has reached the maxiumum number of distinct fund manager assignments.');
                    }
                });
        }
    }

    public removeFirm(FirmId: string): void {
        this.loadingSpinner.begin();
        this.dataStore.removeFirm(this.userDetail.Id, FirmId);
    }

    public copyFirms(): void {
        const targetUserId = this.copyToUser;
        this.loadingSpinner.begin();
        this.dataStore.copyFirms(this.userDetail.Id, targetUserId)
            .subscribe(() => {
                this.loadingSpinner.end();
                this.firmsCopied = true;
            });
    }

    public copyAnalysts(): void {
        const targetDirectorId = this.copyToDirector;

        this.confirmationDialogService.confirm(
            'Copy Analysts', 
            'Warning: Analysts cannot be assigned to more than one Director. Copying Analysts to a new Director will remove all Analysts from this Director. Are you sure you want to proceed?')
            .then((confirmed) => {
                if(confirmed) {
                    this.loadingSpinner.begin();
                    this.dataStore.copyAnalystsToNewDirector(this.userDetail.Id, targetDirectorId)
                        .subscribe(() => {
                            this.loadingSpinner.end();
                            this.analystsCopied = true;
                        }
                    );
                }
            })
            .catch(() => {

            }
        );
    }

    public assignDirector(): void {
        const targetDirectorId = this.copyToDirector;
        this.loadingSpinner.begin();
        this.dataStore.updateAnalystDirectorRelationship(this.userDetail.Id, targetDirectorId)
            .subscribe(() => {
                this.loadingSpinner.end();
            }
        );
    }

    public userSelectionChanged(): void {
        this.firmsCopied = false;
    }
}
