
// Angular Modules
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

// Additional Modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { ChartModule } from 'angular-highcharts';
import { ConcurrencyModule } from '@concurrency/angular';
import { FormAdvModule } from '../_form-adv/form-adv.module';

export const CommonModules = [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    PDFExportModule,
    ExcelExportModule,
    ChartModule,
    ConcurrencyModule,
    FormAdvModule
];
