import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import * as moment from 'moment';
import { AlertGroup, DashboardAlert, DashboardAlertComment, DashboardFirm, DashboardFirmNew } from 'src/app/_api/entities/alert-data';
import { ExportDashboardRequest } from 'src/app/_api/entities/export-dashboard-request';
import { FormADVService } from 'src/app/_api/services/form-adv-data.service';
import { LoadingSpinner } from 'src/app/_concurrency/spinner/loading-spinner';
import { DataStore } from 'src/app/_form-adv/data/data.store';
import { DocumentLoadingModalComponent, ModalType } from 'src/app/advform/document-loading-modal.component';

export enum DateFilter {
    All = 0,
    ThisYear = 1,
    ThisQuarter = 2,
    ThisMonth = 3
}

@Component({
    selector: 'app-dashboard-excel-export',
    templateUrl: 'dashboard-excel-export.component.html',
    styleUrls: ['../../../assets/css/dashboard-excel-export.scss'] })
export class DashboardExcelExportComponent extends SubscriberEntity implements OnInit {

    private request = {} as ExportDashboardRequest;
    public dashboardData!: DashboardFirmNew[];
    public firmFilters!: any[];
    public selectAllFirms = true;
    public severityFilters!: any[];
    public dateFilters!: any[];
    public acknowledgedFilter!: boolean;
    public showSelectedFirm = false;
    public exportFileName = 'dashboardsummary.xlsx';

    @ViewChild('excelDataLink') public excelDataLink!: ElementRef;

    constructor(
        private modal: NgbActiveModal,
        private modalService: NgbModal,
        private dataStore: DataStore,
        public loadingSpinner: LoadingSpinner,
        public advService: FormADVService
    ) { super(); }

    public onClose(): void {
        this.modal.close();
    }

    public ngOnInit(): void {
        // Configure our "filters"
        this.firmFilters = Array.from(new Set(this.dashboardData
            .map((f: DashboardFirmNew) => {
                return {
                    CrdNumber: f.CrdNumber,
                    Name: f.Name,
                    Id: f.Id,
                    Selected: true
                };
            })
            .sort((a: any, b: any) => {
                return a.Name.localeCompare(b.Name);
            })
        ));

        this.severityFilters = [
            { Severity: 3, Title: 'Critical Alerts', Selected: true },
            { Severity: 2, Title: 'Important Alerts', Selected: true },
            { Severity: 1, Title: 'Notable Alerts', Selected: true }
        ];

        this.dateFilters = [
            { Filter: DateFilter.All, Title: 'All', Selected: true },
            { Filter: DateFilter.ThisYear, Title: 'This Year', Selected: false },
            { Filter: DateFilter.ThisQuarter, Title: 'This Quarter', Selected: false },
            { Filter: DateFilter.ThisMonth, Title: 'This Month', Selected: false }
        ];

        this.acknowledgedFilter = true;
        const startDate = new Date(1970, 1, 1);
        this.request = {
            alertSeverities: [1, 2, 3],
            allFirmsSelected: true,
            filingsSince: startDate,
            includeAcknowledgedAlerts: true,
            targetFirms: this.dashboardData.map((f) => f.Id)
        } as ExportDashboardRequest;
    }

    public updateFirmSelection(): void {
        this.firmFilters = this.firmFilters.map((f: DashboardFirm) => {
            return {
                CrdNumber: f.CrdNumber,
                Name: f.Name,
                Id: f.Id,
                Selected: this.selectAllFirms
            };
        });
    }

    public exportToExcel(component: ExcelExportComponent): void {
        const modalRef = this.modalService.open(DocumentLoadingModalComponent, { windowClass: 'pdf-loading-modal-container'});
        modalRef.componentInstance.modalType = ModalType.Excel;

        this.request.alertSeverities = this.severityFilters.filter((s) => s.Selected === true).map((s) => s.Severity);
        this.request.allFirmsSelected = this.selectAllFirms;
        this.request.includeAcknowledgedAlerts = this.acknowledgedFilter;
        const activeDateFilter = this.dateFilters.find((df) => df.Selected === true);
        this.request.filingsSince = this.getFilterDate(activeDateFilter.Filter);
        this.request.targetFirms = this.firmFilters.filter((ff) => ff.Selected === true).map((ff) => ff.Id);

        this.advService.exportAlerts(this.request)
        .subscribe((exportResponse) => {
            const dataType = exportResponse.type;
            const data = [];
            data.push(exportResponse);
            this.excelDataLink.nativeElement.href = window.URL.createObjectURL(new Blob(data, {type: dataType}));
            this.excelDataLink.nativeElement.download = 'DashboardExport.xlsx';
            this.excelDataLink.nativeElement.click();
            this.modalService.dismissAll();
        });

    }

    private getFilterDate(dateFilter: DateFilter): Date {
        switch(dateFilter) {
            case DateFilter.All: {
                return new Date(1970, 1, 1);
            }
            case DateFilter.ThisYear: {
                const thisYear = moment().year();
                return new Date(thisYear, 12, 31);
            }
            case DateFilter.ThisMonth: {
                const thisYear = moment().year();
                const thisMonth = moment().month();
                return new Date(thisYear, thisMonth, 1);
            }
            case DateFilter.ThisQuarter: {
                const thisYear = moment().year();
                const thisQuarter = moment().startOf('quarter').month();
                return new Date(thisYear, thisQuarter, 1);
            }
            default:
                return new Date(1970, 1, 1);
        }
    }

    public firmFilterChanged(firmFilter: any): void {
        if (firmFilter && firmFilter.target && firmFilter.target.value) {
            const crdNumber = firmFilter.target.value;
            const filterToFind = this.firmFilters.find((ff: any) => ff.CrdNumber.toString() === crdNumber.toString());
            if (filterToFind) { filterToFind.Selected = firmFilter.target.checked; }
        }
    }

    public severityFilterChanged(severityFilter: any): void {
        if (severityFilter && severityFilter.target && severityFilter.target.value) {
            const severity = severityFilter.target.value;
            const filterToFind = this.severityFilters.find((sf: any) => sf.Severity.toString() === severity.toString());
            if (filterToFind) { filterToFind.Selected = severityFilter.target.checked; }
        }
    }

    public dateFilterChanged(dateFilter: any): void {
        if (dateFilter && dateFilter.target && dateFilter.target.value) {
            const dateRange = dateFilter.target.value;

            this.dateFilters = this.dateFilters.map((df) => {
                return {
                    Filter: df.Filter,
                    Title: df.Title,
                    Selected: false
                };
            });

            const filterToFind = this.dateFilters.find((df: any) => df.Filter.toString() === dateRange.toString());
            if (filterToFind) { filterToFind.Selected = dateFilter.target.checked; }
        }
    }
}