import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { toString } from '@ng-bootstrap/ng-bootstrap/util/util';
import { AdvformDetailData } from '../../../app/_api/entities/advform-data';
import { AdvFormDetailService } from '.././services/advform-detail-service.component';

@Component({
    selector: 'advform-item7',
    templateUrl: './advform-item7.component.html',
    styleUrls: ['../../advform/advform.component.scss']
})
export class AdvformItem7Component implements OnInit {
    private collapseToggle = false;

    @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
    @Input('detail') public detail: AdvformDetailData | undefined;

    constructor(public advHelper: AdvFormDetailService) { }

    public sec7b1s = new Array<any>();
    public sec7b2s = new Array<any>();
    public sd7As = new Array<any>();
    

    public ngOnInit(): void {
        this.sec7b1s = this.advHelper.getDeepObjectArray(this.detail, '7B').sort((a, b) => { 
            if (a.Q7B_1_A > b.Q7B_1_A) {
                return 1; 
            }
            if(a.Q7B_1_A < b.Q7B_1_A) {
                return -1;
            }
            return 0;
        });
        this.sec7b2s = this.advHelper.getDeepObjectArray(this.detail, '7B2');
        this.sd7As = this.advHelper.getDeepObjectArray(this.detail, 'ScheduleD.SD_7_A');

            }

    splitDescription(value: string) {
        if(value)
        {
            return value.split(';');
        }
        else
        {
            return value;
        }
   }

    public toggleCollapseEvent(): void {
        this.collapseToggle = !this.collapseToggle;
        this.collapseEvent.emit(this.collapseToggle);
    }

    public getSplitYn(value: string, index: number): string {
       if(value == null)
       {
        return ''
       }
        if (value === 'Y') {
            return 'Y';
        }
        const splits = value.split(',');
        if (index < splits.length) {
            return splits[index];
        }
        return '';
    }

    public getSplitValue(value: string, index: number): string {

        if(value == null)
        {
         return ''
        }
        
        const splits = value.split(',');
        if (index < splits.length) {
            return splits[index].trim();
        }
        return '';
    }
}
