﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '@concurrency/angular';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../entities/user-profile';

@Injectable()
export class UserProfileService extends ApiClient {

    protected baseUrl: string = `${environment.urls.apiUrl}/api`;

    public read(): Observable<UserProfile> {
        return this.get(`userprofile`);
    }

    public acceptTerms(marketingOptIn: boolean): Observable<UserProfile> {
        return this.post(`userprofile/acceptTerms?marketingOptIn=${marketingOptIn}`, {});
    }
}
