import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AdvformDetailData } from '../../_api/entities/advform-data';
import { AdvFormDetailService } from '../services/advform-detail-service.component';

@Component({
  selector: 'advform-item1',
  templateUrl: './advform-item1.component.html',
  styleUrls: ['../../advform/advform.component.scss']
})
export class AdvformItem1Component implements OnInit {
  private collapseToggle: boolean = false;

  @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
  @Input('detail') public detail: AdvformDetailData | undefined;

  public cikNumbers?: string = '';
  public crdNumbers?: string = '';

  public sd_1_Bs = new Array<any>();
  public sd_1_Fs = new Array<any>();
  public sd_1_Is = new Array<any>();
  public sd_1_Ls = new Array<any>();
  public sd_1_Ms = new Array<any>();

  constructor(public advFormDetailService: AdvFormDetailService) { }

  public ngOnInit(): void {
    if(this.detail && this.detail.FormInfo && this.detail.Item1) {
      if(this.detail && this.detail.Item1.Q1_D_3 && this.detail && this.detail.Item1.Q1_D_3.length > 0) {
        this.cikNumbers = this.detail.Item1.Q1_D_3.join(",");
      }
      if(this.detail && this.detail.Item1.Q1_E_2_List && this.detail && this.detail.Item1.Q1_E_2_List.length > 0) {
        this.crdNumbers = this.detail.Item1.Q1_E_2_List.join(",");
      }
      else if(this.detail && this.detail.Item1.Q1_E_2 ) {
        this.crdNumbers = this.detail.Item1.Q1_E_2;
      }
    }

    this.sd_1_Bs = this.advFormDetailService.getDeepObjectArray(this.detail, 'ScheduleD.SD_1_B');
    this.sd_1_Fs = this.advFormDetailService.getDeepObjectArray(this.detail, 'ScheduleD.SD_1_F');
    this.sd_1_Is = this.advFormDetailService.getDeepObjectArray(this.detail, 'ScheduleD.SD_1_I');
    this.sd_1_Ls = this.advFormDetailService.getDeepObjectArray(this.detail, 'ScheduleD.SD_1_L');
    this.sd_1_Ms = this.advFormDetailService.getDeepObjectArray(this.detail, 'ScheduleD.SD_1_M');
  }

  public toggleCollapseEvent() {
    this.collapseToggle = !this.collapseToggle;
    this.collapseEvent.emit(this.collapseToggle);
  }

}
