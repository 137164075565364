﻿import { NgModule } from '@angular/core';
import { CommonModules } from '../_config/common-modules';

// Components
import { EmployeeLoginComponent } from './employee-login.component';
import { CustomerLoginComponent } from './customer-login.component';
import { HomeComponent } from './home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './admin/user.component';
import { AlertComponent } from './dashboard/alert.component';
import { AcceptTermsModal } from './modals/accept-terms-modal.component';
import { ErrorService } from '../_form-adv/error/error.service';
import { RetrieveNestedObjPipe } from '../_concurrency/pipes/retrieveNestedObjValue';
import { ModalComponent } from '@concurrency/angular/dist';
import { CreateUserComponent } from './admin/create-user.component';
import { FirmCardComponent, HighlightSearch } from './dashboard/firm-card-component';
import { ReportsSearchComponent, BoldSearch } from './reports/reports-search.component';
import { ReportsFirmSummaryComponent } from './reports/reports-firm-summary.component';
import { ReportsFundSummaryComponent } from './reports/reports-fund-summary.component';
import { FirmFilingDetailsComponent } from './firm-filing-details/firm-filing-details.component';
import { FirmFilingDetailsCardComponent } from './firm-filing-details/firm-filing-details-card.component';
import { FirmFilingDetailsAlertComponent } from './firm-filing-details/firm-filing-details-alert.component';
import { LandingComponent } from './landing.component';
import { LoginRedirectComponent } from './login-redirect.component';
import { FieldTypeFormatPipe } from './dashboard/alert-formatting.pipe';
import { ConfirmationDialogComponent } from './modals/confirmation/confirmation-dialog.component';
import { ConfirmationDialogService } from './modals/confirmation/confirmation-dialog.service';
import { DashboardExcelExportComponent } from './dashboard/dashboard-excel-export.component';
import { ReportsFirmDrpComponent } from './reports/reports-firm-drp-component';;
import { FirmWorkspaceComponent } from './firm-workspace/firm-workspace.component'
import { DataService } from './services/data.service';

@NgModule({
    declarations: [
        EmployeeLoginComponent,
        CustomerLoginComponent,
        HomeComponent,
        DashboardComponent,
        DashboardExcelExportComponent,
        AdminComponent,
        UserComponent,
        AlertComponent,
        FieldTypeFormatPipe,
        AcceptTermsModal,
        RetrieveNestedObjPipe,
        CreateUserComponent,
        FirmCardComponent,
        HighlightSearch,
        ReportsSearchComponent,
        BoldSearch,
        ReportsFirmSummaryComponent,
        ReportsFundSummaryComponent,
        FirmFilingDetailsComponent,
        FirmFilingDetailsCardComponent,
        FirmFilingDetailsAlertComponent,
        LandingComponent,
        LoginRedirectComponent,
        ConfirmationDialogComponent,
        ReportsFirmDrpComponent,
        FirmWorkspaceComponent
    ],
    entryComponents: [
        AcceptTermsModal,
        ModalComponent,
        LandingComponent,
        ConfirmationDialogComponent,
        DashboardExcelExportComponent
    ],
    imports: [
        ...CommonModules
    ],
    providers: [
        ErrorService,
        ConfirmationDialogService,
        DataService
    ]
})
export class HomeModule { }
