import { Component, Input, OnInit } from '@angular/core';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-textarea',
    templateUrl: 'advform-textarea.component.html',
    styleUrls: ['advform-textarea.component.scss']
})
export class AdvFormTextAreaComponent implements OnInit {

    @Input('formObj') public formObj: any;
    @Input('referenceString') public referenceString = '';
    @Input('explicitValue') public explicitValue = '';
    @Input('redcolor') public redcolor = 'N';

    public displayText = '';
    public fullWidth = false;
    public isredcolor = false;
    


    constructor(
        private advFormDetailService: AdvFormDetailService
    ) {}

    public ngOnInit(): void {
        if (this.referenceString && this.referenceString !== '') {
            const value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);
            this.displayText = value;
        } else if (this.explicitValue && this.explicitValue !== '') {
            this.displayText = this.explicitValue;
        }

        if (this.displayText && this.displayText.length > 300) {
            this.fullWidth = true;
        }
        if(this.redcolor == 'Y'){
            this.isredcolor = true;
        }
    }

}
