import { Component, OnInit, Input, OnChanges, Pipe, PipeTransform, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { LoadingSpinner } from '../../_concurrency/spinner/loading-spinner';
import { FirmFilingDetailsFilingResponse, FirmFilingDetailsAlertResponse } from 'src/app/_api/entities/firm-filing-details';
import { DashboardAlert, AlertGroup } from 'src/app/_api/entities/alert-data';

@Pipe({
    name: 'highlight'
})
export class HighlightSearch implements PipeTransform {

    transform(value: string, args: string): any {
        if (args && value) {
            let startIndex = value.toLowerCase().indexOf(args.toLowerCase());
            if (startIndex != -1) {
                let endLength = args.length;
                let matchingString = value.substr(startIndex, endLength);
                return value.replace(matchingString, "<mark>" + matchingString + "</mark>");
            }
    
        }
        return value;
    }
}

@Component({
    selector: 'app-firm-filing-details-card',
    templateUrl: 'firm-filing-details-card.component.html',
    styleUrls: ['../../../assets/css/firm-card.scss']
})export class FirmFilingDetailsCardComponent extends SubscriberEntity implements OnInit, OnChanges {

    @Input('firmFiling') public firmFiling!: FirmFilingDetailsFilingResponse;
    @Input('searchText') public searchText = '';
    public isTextFiltered = false;
    public alertGroups!: AlertGroup[];
    public crdNo: string = '';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public loadingSpinner: LoadingSpinner
    ) { super(); }

    public ngOnChanges(changes: import ('@angular/core').SimpleChanges): void {
        this.isTextFiltered = this.isFiltered(this.searchText);
    }

    public ngOnInit(): void {
        this.alertGroups = this.mapToAlertGroups(this.firmFiling.Alerts);
        let url=window.location.href;
        this.crdNo = url.split("/").reverse()[0];
    }

    public mapToAlertGroups(alerts: FirmFilingDetailsAlertResponse[]): AlertGroup[]  {
        if(!alerts) return [];

        const mappedAlerts: DashboardAlert[] = alerts.map((alert) => {
            const dashboardAlert: DashboardAlert = {
                Id: alert.Id,
                FirmCrd: "",
                Severity: alert.Severity,
                Title: alert.Title,
                ShortDescription: alert.ShortDescription,
                LongDescription: alert.LongDescription,
                FieldChange: alert.FieldChange,
                CurrentValue: alert.CurrentValue,
                PreviousValue: alert.PreviousValue,
                FundName: alert.FundName,
                FieldType: alert.FieldType,
                AlertRuleId: alert.AlertRuleId,
                Acknowledged: false,
                Comments: [],
                Date: alert.Date
            };

            return dashboardAlert;
        });

        const groupedAlerts: AlertGroup[] = [
            { Sort: 1,  Label: 'Critical Updates', CssClass: 'Critical', Severity: 3,
                FontAwesomeIcon: 'fa-exclamation-triangle', Alerts: mappedAlerts.filter((i) => i.Severity === 3).sort((a: DashboardAlert, b: DashboardAlert) => {
                    if ( a.ShortDescription.startsWith("DRP")) return 1;
                    else if (b.ShortDescription.startsWith("DRP")) return -1
                    else return a.ShortDescription.localeCompare(b.ShortDescription, undefined, {
                         numeric: true,
                         sensitivity: 'base',
 
                       }) 
 
                 })
            
            },
            { Sort: 2, Label: 'Important Updates', CssClass: 'Medium', Severity: 2,
                FontAwesomeIcon: 'fa-exclamation-circle', Alerts: mappedAlerts.filter((i) => i.Severity === 2).sort((a: DashboardAlert, b: DashboardAlert) => {
                    if ( a.ShortDescription.startsWith("DRP")) return 1;
                    else if (b.ShortDescription.startsWith("DRP")) return -1
                    else return a.ShortDescription.localeCompare(b.ShortDescription, undefined, {
                         numeric: true,
                         sensitivity: 'base',
 
                       }) 
 
                 })
                 },
            { Sort: 3, Label: 'Notable Updates', CssClass: 'Low', Severity: 1,
                FontAwesomeIcon: 'fa-check-circle', Alerts: mappedAlerts.filter((i) => i.Severity === 1).sort((a: DashboardAlert, b: DashboardAlert) => {
                    if ( a.ShortDescription.startsWith("DRP")) return 1;
                    else if (b.ShortDescription.startsWith("DRP")) return -1
                    else return a.ShortDescription.localeCompare(b.ShortDescription, undefined, {
                         numeric: true,
                         sensitivity: 'base',
 
                       }) 
 
                 })
                 }
        ];

        return groupedAlerts;
    }

    public navigateToAlertDetail(Id: string): void {
        let alertId =  Id + "|" + this.crdNo + "|" + this.firmFiling.FilingDate.toString();
        this.router.navigate(['alert', alertId], {relativeTo: this.route});
    }

    public toggleCollapsed(event: any): void {
        const parentElement: HTMLElement = event.currentTarget.parentElement;
        if (parentElement) {
            if (parentElement.classList.contains('collapsed')) {
                parentElement.classList.remove('collapsed');
            } else {
                parentElement.classList.add('collapsed');
            }
        }
    }

    private isFiltered(searchText: string): boolean {
        searchText = searchText.toLocaleLowerCase();
        if (searchText === '') { return true; }

        if (this.firmFiling) {

            // Alert Title & Description
            let alertData: FirmFilingDetailsAlertResponse[] = [];
            this.firmFiling.Alerts.forEach((group) => {
                alertData = alertData.concat(group);
            });
            console.log(alertData);
            const titleMatch = alertData.filter((alert) => alert.Title.toLowerCase().indexOf(searchText) > -1);
            const descriptionMatch = alertData.filter((alert) => alert.ShortDescription.toLowerCase().indexOf(searchText) > -1);
            if (titleMatch.length > 0 || descriptionMatch.length > 0) { return true; }

            return false;
        }
        return false;
    }
}
