import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvformDetailData } from '../../_api/entities/advform-data';
import { AdvFormDetailService } from '../services/advform-detail-service.component';

@Component({
    selector: 'advform-scheduler',
    templateUrl: './advform-scheduler.component.html',
    styleUrls: ['../../advform/advform.component.scss']
})
export class AdvformScheduleRComponent implements OnInit {
    private collapseToggle = false;

    @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
    @Input('detail') public detail: AdvformDetailData | undefined;

    public schedRs = new Array<any>();

    constructor(public advHelper: AdvFormDetailService) { }

    public ngOnInit(): void {
        this.schedRs = this.advHelper.getDeepObjectArray(this.detail, 'ScheduleR');
    }

    public toggleCollapseEvent(): void {
        this.collapseToggle = !this.collapseToggle;
        this.collapseEvent.emit(this.collapseToggle);
    }

    public compareValue(actual: string, expected: string, returnVal: string): string {
        if (actual === expected) {
            return returnVal;
        }
        return '';
    }

    public getTableRows(root: any, path: string): string[] {
        const val = this.advHelper.getDeepObjectValue(root, path);

        if (val === undefined || val == null || val === '') {
            return new Array<string>();
        }

        const rows = val.split('\n');
        return rows.length > 0 ? rows.slice(1) : new Array<string>();
    }

    public getTableValue(root: any, path: string, row: number, part: number): string {
        const val = this.advHelper.getDeepObjectValue(root, path);

        if (val === undefined || val == null || val === '') {
            return '';
        }

        const rows = val.split('\n');
        const parts: string[] = rows[row].split(';');
        if (parts.length > part) {
            return parts[part];
        }

        return '';
    }
    public splitValue(root: any, path: string, part: number): string {
        const val = this.advHelper.getDeepObjectValue(root, path);

        if (val === undefined || val === null || val === '') {
            return '';
        }

        const parts: string [] = val.split(',');
        if (part < parts.length) {
            if (part === 0) {
                return parts[0];
            }
            let res = '';
            for (let i = 1; i < parts.length; i++) {
                res += parts[i];
                if (i + 1 < parts.length) {
                    res += ', ';
                }
            }
            return res;
        }
        return '';
    }

    public splitString(val: string, part: number): string {
        if (val === undefined || val === null || val === '') {
            return '';
        }
        const parts: string[] = val.split(';');
        if (part < parts.length) {
            return parts[part];
        }
        return '';
    }
}
