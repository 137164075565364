import { Injectable } from "@angular/core";
import { ApiClient } from "@concurrency/angular";
import { environment } from "src/environments/environment";
import { Authorization } from "../entities/authorization";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AuthorizationService extends ApiClient {
    
    protected baseUrl: string = `${environment.urls.apiUrl}/api`;

    public read(): Observable<Authorization> {
        const result: Observable<Authorization> = this.get(`Authorization`);
        return result.pipe(map((data: {}) => {
            const roles: string[] = [];
            let hasSubscription = false;
            let subscriptionId;

            for (const row of data as string[]) {
                if (row.indexOf('http://schemas.microsoft.com/ws/2008/06/identity/claims/role') > -1) {
                    roles.push(row.split(' : ')[1]);
                } else if (row.indexOf('DuffAndPhelps.HasSubscription') > -1) {
                    hasSubscription = row.split(' : ')[1] === 'True';
                } else if (row.indexOf('DuffAndPhelps.Subscriptions') > -1) {
                    subscriptionId = row.split(' : ')[1];
                }
            }

            return {
                Roles: roles,
                HasSubscription: hasSubscription,
                SubscriptionId: subscriptionId
            };
        }));
    }
}
