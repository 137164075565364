import { NavigationItem } from '../_api/entities/navigation-item';

export const navigationItems: NavigationItem[] = [
    {
        anchorHref: 'item1',
        anchorText: 'Item 1: Identifying Information'
    },
    {
        anchorHref: 'item2',
        anchorText: 'Item 2: SEC Registration/Reporting'
    },
    {
        anchorHref: 'item3',
        anchorText: 'Item 3: Form of Organization'
    },
    {
        anchorHref: 'item4',
        anchorText: 'Item 4: Successions'
    },
    {
        anchorHref: 'item5',
        anchorText: 'Item 5: Information About Your Advisory Business'
    },
    {
        anchorHref: 'item6',
        anchorText: 'Item 6: Other Business Activities'
    },
    {
        anchorHref: 'item7',
        anchorText: 'Item 7: Financial Industry Affiliations'
    },
    {
        anchorHref: 'item8',
        anchorText: 'Item 8: Participation or Interest in Client Transactions'
    },
    {
        anchorHref: 'item9',
        anchorText: 'Item 9: Custody'
    },
    {
        anchorHref: 'item10',
        anchorText: 'Item 10: Control Persons'
    },
    {
        anchorHref: 'item11',
        anchorText: 'Item 11: Disclosure Information'
    },
    {
        anchorHref: 'item12',
        anchorText: 'Item 12: Small Businesses'
    },
    {
        anchorHref: 'schedulea',
        anchorText: 'Schedule A:'
    },
    {
        anchorHref: 'scheduleb',
        anchorText: 'Schedule B:'
    },
    
    {
        anchorHref: 'scheduled',
        anchorText: 'Schedule D:'
    },
    
    {
        anchorHref: 'scheduler',
        anchorText: 'Schedule R:'
    },
    {
        anchorHref: 'drppages',
        anchorText: 'DRPs:'
    },
    {
        anchorHref: 'brochures',
        anchorText: 'Brochures:'
    },
    {
        anchorHref: 'signaturepage',
        anchorText: 'Signature Page:'
    }
];
