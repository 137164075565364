import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFExportComponent, PDFMarginComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { AdvformDetailData } from '../../app/_api/entities/advform-data';
import { NavigationItem } from '../_api/entities/navigation-item';
import { FormADVService } from '../_api/services/form-adv-data.service';
import { LoadingSpinner } from '../_concurrency/spinner/loading-spinner';
import { DocumentLoadingModalComponent, ModalType } from './document-loading-modal.component';
import { PageMargin } from '@progress/kendo-drawing/pdf';
import * as moment from 'moment';

@Component({
  templateUrl: './advform.component.html',
  styleUrls: ['./advform.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvformComponent extends SubscriberEntity implements OnInit {

  public detail?: AdvformDetailData;
  public navigationItems = new Array<NavigationItem>();
  public trackedAnchorIds = new Array<string>();
  public advformInitialized = false;
  public recalculateContainers = false;
  public legalName = '';
  public crdNumber = '';
  public pdfActive = false;
  public filingDateString = '';

  @ViewChild('pdf') public pdf!: PDFExportComponent;

  @ViewChild('pdfDataLink') public pdfDataLink!: ElementRef;
  @ViewChild('advForm') public advForm!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private formADVService: FormADVService,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    public loadingSpinner: LoadingSpinner
  ) {
    super();
    this.detail = undefined;
  }

  public ngOnInit(): void {
    this.loadingSpinner.begin();
    const crdNumber = this.route.snapshot.params.crdNumber;
    const filingDate = this.route.snapshot.params.filingDate;
    const m = moment(filingDate);
    this.filingDateString = m.format('MMM DD YYYY');
    this.formADVService.getFormAdv(crdNumber, filingDate).subscribe((response) => {
      if (response) {
        this.detail = response;
        if (this.detail != null) {
          this.legalName = this.detail.Info.LegalNm;
          this.crdNumber = this.detail.Info.FirmCrdNb;
        }
      }
      this.loadingSpinner.end();
    });
  }

  public formInitialized(emittedEvent: boolean): void {
    this.advformInitialized = emittedEvent;
    this.cdRef.detectChanges();
  }

  public handleRecalculateContainers($event: boolean): void {
    this.recalculateContainers = $event;
  }

  public get pdfMargin(): PageMargin {
    const margins: PageMargin = {
      left: "0.5cm",
      right: "0.5cm",
      top: "2.1cm",
      bottom: "1cm"
    }
    return margins;
  }

  public savePdf(): void {
    if (this.pdf != null) {
      this.advForm.nativeElement.className = 'col-md-9 formadv-container k-exportpdf';
      const modalRef = this.modalService.open(DocumentLoadingModalComponent, { windowClass: 'pdf-loading-modal-container'});
      modalRef.componentInstance.modalType = ModalType.PDF;

      this.pdf.export().then((group: Group) => {
        exportPDF(group).then((dataUri: string) => {
          this.pdfDataLink.nativeElement.href = dataUri;
          this.pdfDataLink.nativeElement.download = `formadv-${this.crdNumber}`;
          this.pdfDataLink.nativeElement.click();
          this.advForm.nativeElement.className = 'col-md-9 formadv-container';
          this.modalService.dismissAll();
        });
      });
    }
  }
}
