import { Component, OnInit, PipeTransform, Pipe } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, subscribeOn, mergeMap } from 'rxjs/operators';
import { DataStore } from 'src/app/_form-adv/data/data.store';
import { FirmsReportsSearchResult, FundsReportsSearchResult } from 'src/app/_api/entities/reports';
import { Router } from '@angular/router';
import { LoadingSpinner } from 'src/app/_concurrency/spinner/loading-spinner';

@Pipe({
    name: 'bold'
})
export class BoldSearch implements PipeTransform {

    transform(value: string, args: string): any {
        if (args && value) {
            let startIndex = value.toLowerCase().indexOf(args.toLowerCase());
            if (startIndex != -1) {
                let endLength = args.length;
                let matchingString = value.substr(startIndex, endLength);
                return value.replace(matchingString, `<span class="bold-search">${matchingString}</span>`);
            }
    
        }
        return value;
    }
}

@Component({ templateUrl: 'reports-search.component.html', styleUrls: ['../../../assets/css/reports.scss'] })
export class ReportsSearchComponent extends SubscriberEntity implements OnInit {
   
    private searchTextChanged = new Subject<string>();

    public searchText = '';

    public searchingFirms = false;
    public firmsSearchResult:FirmsReportsSearchResult | undefined = undefined;

    public searchingFunds = false;
    public fundsSearchResult:FundsReportsSearchResult | undefined = undefined;

    constructor(
        private dataStore: DataStore,
        private router: Router,
        public loadingSpinner: LoadingSpinner) { super(); }

    public ngOnInit(): void {
        this.searchTextChanged
        .pipe(
            debounceTime(250),
            distinctUntilChanged(),
            tap(() => (this.searchingFirms = true)),
            switchMap((searchText) => {
                if(searchText.length > 0) {
                    return this.dataStore.searchReportFirms(searchText) 
                    .pipe(
                        tap(() => (this.searchingFirms = false)),
                        catchError(() => {
                            return new Observable<FirmsReportsSearchResult>();
                        })
                    )
                } else {
                    this.searchingFirms = false;
                    return new Observable<undefined>();
                }
            })
        )
        .subscribe((firmsSearchResult) => {
            this.firmsSearchResult = firmsSearchResult;
        });

        this.searchTextChanged
        .pipe(
            debounceTime(250),
            distinctUntilChanged(),
            tap(() => (this.searchingFunds = true)),
            switchMap((searchText) => {
                if(searchText.length > 0) {
                    return this.dataStore.searchReportFunds(searchText) 
                    .pipe(
                        tap(() => (this.searchingFunds = false)),
                        catchError(() => {
                            return new Observable<FundsReportsSearchResult>();
                        })
                    )
                } else {
                    this.searchingFunds = false;
                    return new Observable<undefined>();
                }
            })
        )
        .subscribe((fundsSearchResult) => {
            this.fundsSearchResult = fundsSearchResult;
        });
    }

    public search(): void {
        if(this.searchText.length === 0) {
            this.firmsSearchResult = undefined;
            this.fundsSearchResult = undefined;
        }
        this.searchTextChanged.next(this.searchText);
    }

    public navigateToFirmSummary(crdNumber: string): void {
        this.router.navigate(['/home/reports/firm', crdNumber]);
    }

    public navigateToFundSummary(crdNumber: string, secNumber: string): void {
        this.router.navigate(['/home/reports/fund', crdNumber, secNumber]);
    }
}
