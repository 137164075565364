import {Component, OnInit} from '@angular/core';
import { environment } from '../../environments/environment';
import { UserStore } from '../_form-adv/user/user.store';
import { Router } from '@angular/router';

@Component({ template: '' })
export class LandingComponent implements OnInit {
    constructor(private userStore: UserStore,
        private router: Router) { }

    public ngOnInit(): void {
        window.open(environment.urls.landingPage, '_self');
    }
}
