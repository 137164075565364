import { Injectable } from '@angular/core';
import { UserStore, CurrentUser } from '../../_form-adv/user/user.store';
import { NetworkManager, ModalManager } from '@concurrency/angular';
import { UserProfile } from 'src/app/_api/entities/user-profile';

@Injectable()
export class ErrorService {

    constructor(
        private networkManager: NetworkManager,
        private modalManager: ModalManager,
        private userStore: UserStore
    ) {}

    public handleInvalidUser(user: CurrentUser, profile: UserProfile): void {
        if (new Date(profile.SubscriptionEndDate) <= new Date() && !profile.Active) {
            this.createErrorModal(` Your profile is inactive and we are having issues in retrieving your subscription.
                        Please refer to your local admin for assistance.`);
            return;
        } else if (new Date(profile.SubscriptionEndDate) <= new Date() && profile.Active) {
            this.createErrorModal(` User subscription has expired or it is missing.
                            Please refer to your local admin for assistance.`);
            return;
        } else if (!profile.Active) {
            this.createErrorModal(` Your profile is inactive. Please refer to your local admin for assistance.`);
            return;
        }

        if (!user.hasAccess) {
            this.createErrorModal(` You do not have access to proceed. Please refer to your local admin for assistance.`);
        }
    }


    private createErrorModal(errorText: string): void {
        this.modalManager
            .error(errorText)
            .subscribe(() => {
                this.networkManager.acknowledgeErrors();
                this.userStore.logoutUserOrEmployee();
            });

        setTimeout(() => {
            this.userStore.logoutUserOrEmployee();
        }, 15000);
    }

}
