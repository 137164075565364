import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'advform-radio-value',
    templateUrl: 'advform-radio.component.html',
    styleUrls: ['./advform-radio.component.scss']
})
export class AdvformRadioValueComponent implements OnInit {

    @Input('value') public value!: string;
    @Input('radioName') public radioName = '';

    public checked = false;

    constructor() {}

    public ngOnInit(): void {
        if (this.value === 'Y') {
            this.checked = true;
        }
    }
}
