import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'advform-value',
    templateUrl: 'advform-value.component.html',
    styleUrls: ['advform-value.component.scss']
})
export class AdvFormValueComponent implements OnInit {

    @Input('value') public value = '';

    public displayText = '';

    constructor() {}

    public ngOnInit(): void {
        if (this.value) {
            this.displayText = this.value;
        }
    }

}
