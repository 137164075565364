import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriberEntity } from '@concurrency/angular';
import { AlertDetailsResponse } from 'src/app/_api/entities/firm-filing-details';
import { LoadingSpinner } from '../../_concurrency/spinner/loading-spinner';
import { DataStore } from '../../_form-adv/data/data.store';

@Component({ templateUrl: 'firm-filing-details-alert.component.html', styleUrls: ['../../../assets/css/alert.scss'] })
export class FirmFilingDetailsAlertComponent extends SubscriberEntity implements OnInit {

    public alertDetail!: AlertDetailsResponse;
    public parameters: string ='';
    public crdNo = '';
    public filingDateString = '';

    constructor(
        private route: ActivatedRoute,
        private dataStore: DataStore,
        public loadingSpinner: LoadingSpinner,
        private router: Router,
    ) { super(); }

    public ngOnInit(): void {

        this.parameters = this.route.snapshot.params.alertId;
        let alertId = this.parameters.split('|')[0];
        this.crdNo = this.parameters.split('|')[1];
        this.filingDateString = this.parameters.split('|')[2];
        

        this.loadingSpinner.begin();

        this.dataStore.getAlertDetails(alertId).subscribe((alertDetail: AlertDetailsResponse) => {
            this.alertDetail = alertDetail;
            this.loadingSpinner.end();
        });
    }

    public goBack(): void {
        window.history.go(-1);
    }

    // TODO Need to get the CRD Number and filing date for this to work.
    public gotoFirmADV(crdNumber: string, filingDate: Date): void {
        const url = this.router.serializeUrl(this.router.createUrlTree(['/advform', crdNumber, filingDate ]));
        window.open(url, '_blank');
    }
}
