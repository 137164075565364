import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {AdvformDetailData} from '../../_api/entities/advform-data';

@Component({
  selector: 'advform-schedulea',
  templateUrl: './advform-schedulea.component.html',
  styleUrls: ['../../advform/advform.component.scss']
  })
export class AdvformScheduleAComponent implements OnInit {
  private collapseToggle = false;

  @Output('collapseEvent') public collapseEvent: EventEmitter<boolean> = new EventEmitter(false);
  @Input('detail') public detail: AdvformDetailData | undefined;

  constructor() { }

  public ngOnInit(): void {
  }

  public toggleCollapseEvent(): void {
    this.collapseToggle = !this.collapseToggle;
    this.collapseEvent.emit(this.collapseToggle);
  }

}
