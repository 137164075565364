import { Component, Input, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { AdvFormDetailService } from 'src/app/advform-detail/services/advform-detail-service.component';

@Component({
    selector: 'advform-radio-yesno',
    templateUrl: 'advform-radio-yesno.component.html',
    styleUrls: ['./advform-radio-yesno.component.scss']
})
export class AdvformRadioYesNoComponent extends SubscriberEntity implements OnInit {

    @Input('formObj') public formObj: any;
    @Input('referenceString') public referenceString = '';
    @Input('radioName') public radioName = '';
    @Input('redYes') public redYes = false;
    @Input('value') public value = '';

    public yesChecked = false;
    public noChecked = false;

    constructor(
        private advFormDetailService: AdvFormDetailService
    ) {
        super();
    }

    public ngOnInit(): void {
        let value = '';
        if (this.value) {
            value = this.value;
        }
        else if (this.formObj && this.referenceString) {
            value = this.advFormDetailService.getDeepObjectValue(this.formObj, this.referenceString);
        }
        if (value === 'Y' || value.toLowerCase() === 'yes') {
            this.yesChecked = true;
        } else if (value === 'N' || value.toLowerCase() === 'no') {
            this.noChecked = true;
        }
    }
}
